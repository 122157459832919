import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import './services/errorHandler';
import UrlData from './services/url-data';
import { MainProvider } from './contexts/ContextMain';
import isMobile from './bannerstock/utils/is-mobile';

import OldApp from './Components/App/App';
import HtmlTagAttributes from './Components/HtmlTagAttributes';

import BannerStock from './bannerstock';
import ContextProvider from './bannerstock/context';

const { theme, stockno } = UrlData;
const showNewBannerStock = theme === 'bannerstock' && (stockno || isMobile);
const isBanner250Theme = theme === 'banner250';

ReactDOM.render(
  <React.Fragment>
    {isBanner250Theme && <HtmlTagAttributes />}
    {showNewBannerStock ? (
      <ContextProvider>
        <BannerStock />
      </ContextProvider>
    ) : (
      <MainProvider>
        <CookiesProvider>
          <OldApp />
        </CookiesProvider>
      </MainProvider>
    )}
  </React.Fragment>,
  document.getElementById('root')
);
