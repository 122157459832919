import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SelectGroup = ({ children, handleClose }) => {
  return (
    <div className="select-group">
      <button onClick={handleClose} className="btn-close select-group__close">
        <svg
          width="10"
          height="10"
          fill="none"
          viewBox="0 0 6 6"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.25 0L6 .75.75 6 0 5.25 5.25 0z" fill="#000" />
          <path d="M0 .75L.75 0 6 5.25 5.25 6 2.625 3.375 0 .75z" fill="#000" />
        </svg>
      </button>
      {children}
    </div>
  );
};

SelectGroup.defaultProps = {
  handleClose: () => {}
};

SelectGroup.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func
};

export default SelectGroup;
