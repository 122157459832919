import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import dict from '../../../services/i18n/dict';

import { Context } from '../../context';
import getLotTitle from '../../utils/get-lot-title';

import './styles.scss';

const SelectLot = () => {
  const { lots, lot, pullAndSetQuote, type, destination } = useContext(Context);
  const handleSelectLot = lot => () => {
    pullAndSetQuote(type, destination, lot);
  };

  // In some rare cases we have lots with non unique titles
  // So we add lot_number in title
  // Here we have a lazy algorithm for even duplicate values
  const lotsEnriched = lots.reduce((acc, curr) => {
    let title = getLotTitle(curr);
    const duplicateIdx = acc.findIndex(i => i.title === title);

    if (~duplicateIdx) {
      title = `${curr.lot_number} ${title}`;
      acc[
        duplicateIdx
      ].title = `${acc[duplicateIdx].lot_number} ${acc[duplicateIdx].title}`;
    }

    curr.title = title;

    acc.push(curr);

    return acc;
  }, []);

  return (
    <div className="select-lots">
      <div className="select-lots__title">
        <span className="hide-mobile">
          {dict('Select a vehicle to get a new quote:')}
        </span>

        <span className="hide-desktop">Select a car:</span>
      </div>

      <div className="select-lots__items">
        {lotsEnriched.map(lotItem => (
          <button
            className={classNames('lot', {
              'lot--active': lotItem.lot_number === lot.lot_number
            })}
            onClick={handleSelectLot(lotItem)}
            key={lotItem.lot_number}
          >
            {lotItem.title}
          </button>
        ))}
      </div>
    </div>
  );
};

SelectLot.propTypes = {
  handleClose: PropTypes.func
};

export default SelectLot;
