import parse from 'url-parse';
import get from '../../utils/get';

export default function removeStocknoFromUrl() {
  const url = parse(document.URL, true);
  const query = get(url, 'query', {});
  url.set('query', {
    ...query,
    stockno: ''
  });
  const newUrl = url.toString();
  window.location = newUrl;
}
