// !!! Only for client side !!!
import Cookies from 'universal-cookie';

import config from './config';
import UrlData from '../../services/url-data';

const cookies = new Cookies();

const { split_tests = '' } = UrlData;

const testsFromUrl = split_tests.split(',').filter(Boolean);

const splitTests = {
  inited: false,
  testsOn: [],

  getCookieName(stName) {
    return `ehb_split-${stName}`;
  },

  check(name) {
    return splitTests.testsOn.includes(name);
  },

  setTest(key, maxAge, on = true) {
    if (on) {
      splitTests.testsOn.push(key);
    }

    cookies.set(splitTests.getCookieName(key), on ? 'on' : 'off', {
      maxAge
    });
  },

  init() {
    if (splitTests.inited) {
      return;
    }

    if (testsFromUrl.length) {
      testsFromUrl.forEach(testName => {
        splitTests.setTest(testName, 0);
      });

      return;
    }

    Object.keys(config).forEach(key => {
      const cookieValue = cookies.get(splitTests.getCookieName(key));
      const isOnByCookie = cookieValue === 'on';
      const isOffByCookie = cookieValue === 'off';
      const isOnByRandom = Math.random() >= config[key].value;

      if (!config[key].on && Boolean(cookieValue)) {
        cookies.remove(splitTests.getCookieName(key));
      }

      if (config[key].on) {
        splitTests.setTest(
          key,
          typeof config[key].maxAge !== 'number' ? 3600 : config[key].maxAge,
          !isOffByCookie && (isOnByCookie || isOnByRandom)
        );
      }
    });

    splitTests.inited = true;
  }
};

export default {
  init: splitTests.init,
  check: splitTests.check
};
