import React from 'react';
//components
import ModalWindow from '../ModalWindow';
import { MainConsumer } from '../../contexts/ContextMain';
//style
import './BannerStockTransitTime.css';
import { TypeInternational } from '../../constant';

const BannerStockTransitTime = () => (
  <ModalWindow domNode={document.querySelector('#modal')}>
    <MainConsumer>
      {({ toggleModalStockTransit, typeShipping }) => (
        <div className="banner-stock-transit">
          <div className="banner-stock-transit-logo">
            <img
              src={
                typeShipping === TypeInternational
                  ? 'img/icon-stock-ship.svg'
                  : 'img/icon-stock-car.svg'
              }
              alt="logo-banner-transit"
            />
          </div>
          <div className="banner-stock-transit-text">
            {typeShipping === TypeInternational ? (
              <>
                Rates are based on LCL consolidating 1 vehicle out of 3 in a
                40ft container.
              </>
            ) : (
              <>
                Your vehicle will be picked within 1-3 business days of
                receiving payment for your shipping order. If we’re not able to
                pick up within 5 days, we’ll cover any storage fees charged
                after the 5th day.
              </>
            )}
            <p>
              We’re here to help! Call us at&nbsp;
              <a href="tel:+13605979320">+1 (360) 597-9320</a>
              &nbsp;or email&nbsp;
              <a href="mailto:info@EasyHaul.com">info@EasyHaul.com</a>
            </p>
          </div>
          <div onClick={toggleModalStockTransit} className="close-transit">
            <svg
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 6 6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.25 0L6 .75.75 6 0 5.25 5.25 0z" fill="#000" />
              <path
                d="M0 .75L.75 0 6 5.25 5.25 6 2.625 3.375 0 .75z"
                fill="#000"
              />
            </svg>
          </div>
        </div>
      )}
    </MainConsumer>
  </ModalWindow>
);

export default BannerStockTransitTime;
