import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import { TypeDomestic } from '../../constant';

import './QuoteButton.css';

const QuoteButton = () => {
  const handleDataChecking = (countryState, quoteParameters) => {
    if (countryState.zip && quoteParameters) {
      return '';
    } else {
      return 'quote-cancel__disable';
    }
  };

  return (
    <MainConsumer>
      {({
        countryState,
        typeShipping,
        quoteParameters,
        handleGetQuoteUSA,
        handleQuoteCancel,
        handleGetQuoteInter
      }) => (
        <div className="main-quote">
          <div className="quote-button">
            <button
              type="button"
              onClick={
                typeShipping === TypeDomestic
                  ? handleGetQuoteUSA
                  : handleGetQuoteInter
              }
            >
              {dict('Get Quote')}
            </button>
          </div>
          <div
            className={`quote-cancel ${handleDataChecking(
              countryState,
              quoteParameters
            )}`}
          >
            <span onClick={handleQuoteCancel}>{dict('Cancel')}</span>
          </div>
        </div>
      )}
    </MainConsumer>
  );
};

export default QuoteButton;
