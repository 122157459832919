import React from 'react';

import './styles.scss';

const Header = React.memo(() => {
  return (
    <div className="promo">
      <a
        href="https://www.easyhaul.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src="img/eh_logo.svg" alt="logo-banner-info" height="20" />
      </a>
      <div className="promo__title">Transport your IAA vehicle</div>
      <div className="promo__desc">Average pick up time from 1 to 3 days</div>
    </div>
  );
});

export default Header;
