export default {
  ground: {
    label:
      'Your vehicle will be picked within 1-3 business days of receiving payment for your shipping order. If we’re not able to pick up within 5 days, we’ll cover any storage fees charged after the 5th day.',
    mobileLabel: 'Your vehicle will be picked within 1-5 business days',
    icon: {
      src: 'icon-stock-car.svg',
      width: 78,
      height: 42
    }
  },
  sea: {
    label:
      'Rates are based on LCL consolidating 1 vehicle out of 3 in a 40ft container.',
    icon: {
      src: 'icon-stock-ship.svg',
      width: 76,
      height: 42
    }
  }
};
