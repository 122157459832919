import React from 'react';
import parse from 'url-parse';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import get from '../../utils/get';
import {
  TypeDomestic,
  TypeInternational,
  TypeCanadaMexico
} from '../../constant';

const CustomQuoteLink = () => {
  return (
    <MainConsumer>
      {({
        countriesList,
        countryState,
        make,
        model,
        quoteParameters,
        typeShipping: type,
        lotData,
        year
      }) => {
        const {
          auction_location_city,
          auction_location_state_code,
          auction_location_zip
        } = lotData;
        const fromLotData = {
          zip: auction_location_zip,
          state_code: auction_location_state_code,
          city: auction_location_city
        };
        const fromQuote = get(quoteParameters, 'origin');
        const parsed = parse(process.env.REACT_APP_EH_SITE_URL);
        let params = {
          make: make || lotData.make,
          model: model || lotData.model,
          year: year || lotData.year,
          from: JSON.stringify(fromQuote || fromLotData),
          customQuote: true,
          type
        };

        if (type === TypeDomestic) {
          const { city, state_code, state, zip } = countryState;

          params.to = JSON.stringify({
            city,
            state_code,
            state,
            zip
          });
        }

        if (type === TypeInternational) {
          const { name } = countryState;
          // In some cases we have wrong country id,
          // So below we find a right one from countries list
          const countryFromList = countriesList.find(obj => obj.name === name);
          const id = get(countryFromList, 'id');

          params.to = JSON.stringify({
            id
          });
        }

        if (type === TypeCanadaMexico) {
          const { id } = countryState;
          params.to = JSON.stringify({
            id
          });
        }
        parsed.set('query', params);

        const link = parsed.toString();

        return (
          <a target="_blank" href={link} rel="noopener noreferrer">
            {dict('Request Custom Quote')}
          </a>
        );
      }}
    </MainConsumer>
  );
};

export default CustomQuoteLink;
