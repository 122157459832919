import { deliveryTypes } from '../constants';

export default function getDestinationTitle(type, destination) {
  let title = '';

  if (type === deliveryTypes.domestic) {
    const { city, state_code, zip } = destination;

    title = [city, state_code, zip].filter(Boolean).join(', ');
  }

  if (type === deliveryTypes.international) {
    const { name, iso_2, countryName } = destination;
    title = name ? [name, iso_2].filter(Boolean).join(', ') : countryName;
  }

  if (type === deliveryTypes.canadaOrMexico) {
    const { name, zip } = destination;

    title = [name, zip].filter(Boolean).join(', ');
  }

  return title;
}
