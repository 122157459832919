import React from 'react';

import dict from '../../services/i18n/dict';

import './LoaderStock.css';

const LoaderStock = () => {
  return (
    <div className="loader-stock">
      <div className="loader-stock-content">
        <div className="loader" />
        <p className="loader-stock-text">{dict('Loading')}...</p>
      </div>
    </div>
  );
};

export default LoaderStock;
