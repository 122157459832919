import requests from './requests';

const Lot = {
  // auction values 1 Copart || 2 IAAI
  // By default we use widget only with IAAI
  get(lot_number, auction = 2) {
    return requests.get('/lot', {
      params: {
        auction,
        lot_number
      }
    });
  },
  async getAll(lot_numbers) {
    let lots = [];
    for (let i = 0; i < lot_numbers.length; i++) {
      try {
        const lot = await this.get(lot_numbers[i]);
        lots.push(lot);
      } catch (e) {}
    }

    return lots;
  }
};

export default Lot;
