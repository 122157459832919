import get from '../../utils/get';

function getUserLang() {
  const langs = get(navigator, 'languages.0', '');

  if (langs) {
    return langs.split('-')[0];
  } else {
    return navigator.language;
  }
}

export default getUserLang;
