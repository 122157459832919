import React, { useContext } from 'react';

import dict from '../../services/i18n/dict';
import get from '../../utils/get';
import formatDecimal from '../utils/format-decimal';
import { Context } from '../context';

import CustomQuoteLink from '../custom-quote-link';

import './styles.scss';

const ActionBar = () => {
  const { quote } = useContext(Context);
  const { total, url } = get(quote, 'quote', {});

  return (
    <div className="action-bar">
      <div className="quote">
        <div className="quote__label">{dict('Shipping quote:')}&nbsp;</div>
        {total ? (
          <div className="quote__value">
            {formatDecimal(total)} <span className="currency">USD</span>
          </div>
        ) : (
          <strong className="quote__not-available danger-text">
            {dict('Not Available')}
          </strong>
        )}
      </div>
      <div>
        {url ? (
          <a
            className="btn btn--primary btn--huge"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="hide-mobile">{dict('Order Shipping Now')}</span>

            <span className="hide-desktop">Place order</span>
          </a>
        ) : (
          <CustomQuoteLink />
        )}
      </div>
    </div>
  );
};

export default ActionBar;
