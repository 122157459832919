import requests from './requests';

const Location = {
  detect() {
    return requests.get('/geo/detect');
  },
  get(latitude, longitude) {
    return requests.get('/geolocation', {
      params: {
        latitude,
        longitude
      }
    });
  },
  getByZip(zip) {
    return requests.get(`/zip/${zip}`);
  },
  getCountries() {
    return requests.get('/destination-country');
  },
  getCountryPorts(id) {
    return requests.get('/destination', {
      params: {
        country: id
      }
    });
  }
};

export default Location;
