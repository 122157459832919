import React, { Component } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { MainConsumer } from '../../contexts/ContextMain';

class AutocompleteComponent extends Component {
  render() {
    const { onPlaceSelected, placeholder, setGooglePlacesActive } = this.props;
    return (
      <MainConsumer>
        {({
          isError,
          usaDestination,
          toggleStockDomesticInputFocus,
          handleSetUsaDestination
        }) => {
          return (
            <Autocomplete
              className={
                isError
                  ? 'input-autocomplete input-autocomplete-error'
                  : 'input-autocomplete'
              }
              style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box'
              }}
              onPlaceSelected={place => {
                onPlaceSelected(place);
              }}
              placeholder={placeholder}
              types={['(regions)']}
              componentRestrictions={{
                country: 'us'
              }}
              onChange={e => {
                const isGooglePlacesActive = Boolean(e.target.value);
                setGooglePlacesActive(isGooglePlacesActive);
                // Seems it is broken for ie11 and useless
                // setGoogleDropPosition(usaDestination);
                handleSetUsaDestination(e);
              }}
              onBlur={() => {
                setGooglePlacesActive(false);
                toggleStockDomesticInputFocus();
              }}
              onFocus={e => {
                if (e.target.value) {
                  setGooglePlacesActive(true);
                }
                toggleStockDomesticInputFocus();
              }}
              value={usaDestination}
            />
          );
        }}
      </MainConsumer>
    );
  }
}

export default AutocompleteComponent;
