import { Location } from '../services/api';
import { deliveryTypes } from '../constants';

export default function pullGeoPosition(countries) {
  return Location.detect()
    .then(location => {
      let newDestination;
      const userCountry =
        countries.find(country => country.iso_2 === location.country) || {};
      const { id, type, ...restUserCountry } = userCountry;

      if (type === deliveryTypes.domestic && location.zip) {
        newDestination = location;
      }

      if (type === deliveryTypes.international) {
        newDestination = {
          countryId: id,
          ...restUserCountry
        };
      }

      if (type === deliveryTypes.canadaOrMexico) {
        // Currently we do nothing
        // Result is to show choose shipping step
      }

      return {
        destination: newDestination,
        type
      };
    })
    .catch(e => {
      // We return empty object
      // User choose destination manually
      return {};
    });
}
