import React from 'react';

import { MainConsumer } from '../../contexts/ContextMain';
import DeliveryPart from '../DeliveryPart';
import TransitPart from '../TransitPart';
import OrderPart from '../OrderPart';
import InputPage from '../InputPage';
import InternationalShipping from '../InternationalShipping';
import QuoteButton from '../QuoteButton';
import CustomDeliveryPart from '../CustomDeliveryPart';
import CustomQuoteButton from '../CustomQuoteButton';

import UrlData from '../../services/url-data';

import './MainContent.css';

const { theme } = UrlData;

const MainContent = () => (
  <div className="main">
    <MainConsumer>
      {({
        changeDestination,
        isCustomQuote,
        handleGetQuoteInter,
        typeShipping,
        currentPortTypeB
      }) => {
        if (changeDestination) {
          return (
            <React.Fragment>
              <InputPage
                handleGetQuoteInter={handleGetQuoteInter}
                typeShipping={typeShipping}
                currentPortTypeB={currentPortTypeB}
              />
              <InternationalShipping theme={theme} />
              <QuoteButton />
            </React.Fragment>
          );
        }

        if (isCustomQuote) {
          return (
            <React.Fragment>
              <CustomDeliveryPart />
              <CustomQuoteButton />
            </React.Fragment>
          );
        }

        return (
          <React.Fragment>
            <DeliveryPart theme={theme} />
            <TransitPart />
            <OrderPart />
          </React.Fragment>
        );
      }}
    </MainConsumer>
  </div>
);

export default MainContent;
