import axios from 'axios';

const api = process.env.REACT_APP_API_ENDPOINT;

const requests = {
  get(url, options, errorHandler) {
    return axios
      .get(`${api}${url}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        ...options
      })
      .then(res => res.data)
      .catch(errorHandler);
  }
};

export default requests;
