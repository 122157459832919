import React from 'react';
import { Async as AsyncSelect } from 'react-select';
import PropTypes from 'prop-types';

import get from '../../utils/get';

class GooglePlacesAutoComplete extends React.Component {
  // eslint-disable-next-line
  autoCompleteService = new google.maps.places.AutocompleteService();
  // eslint-disable-next-line
  placesService = new google.maps.places.PlacesService(
    document.createElement('div')
  );

  state = {
    selectedOption: null,
    inputValue: ''
  };

  handleChange = selectedOption => {
    const { onChange } = this.props;
    const placeId = get(selectedOption, 'value.place_id');

    this.placesService.getDetails(
      {
        placeId
      },
      place => {
        onChange(place);
      }
    );
    this.setState({ selectedOption });
  };

  loadOptions = inputValue => {
    const { types, componentRestrictions } = this.props;

    return new Promise((resolve, reject) => {
      this.autoCompleteService.getPlacePredictions(
        { input: inputValue, types, componentRestrictions },
        (predictions, status) => {
          if (status !== 'OK') {
            reject();
          }
          resolve(
            (predictions || []).map(prediction => ({
              label: prediction.description,
              value: prediction
            }))
          );
        }
      );
    });
  };

  handleInputChange = inputValue => {
    this.setState({ inputValue: inputValue });

    return inputValue;
  };

  render() {
    const { selectedOption } = this.state;
    const {
      className,
      classNamePrefix,
      loadingMessage,
      noOptionMessage,
      onChange,
      placeholder,
      ...restProps
    } = this.props;

    return (
      <AsyncSelect
        className={className}
        classNamePrefix={classNamePrefix}
        loadingMessage={loadingMessage}
        loadOptions={this.loadOptions}
        noOptionsMessage={noOptionMessage}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        placeholder={placeholder}
        value={selectedOption}
        {...restProps}
      />
    );
  }
}
GooglePlacesAutoComplete.defaultProps = {
  types: ['(regions)'],
  componentRestrictions: { country: 'us' },
  noOptionMessage: () => null,
  loadingMessage: () => null
};

GooglePlacesAutoComplete.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  loadingMessage: PropTypes.func,
  noOptionMessage: PropTypes.func
};

export default GooglePlacesAutoComplete;
