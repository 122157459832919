import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';

import './OrderPart.css';

const OrderPart = () => {
  const handleFormatNumber = num => num.toLocaleString('en-IN');
  const priceText = dict('Shipping quote:');

  return (
    <MainConsumer>
      {({ quoteParameters, sendEventAndRedirectOnOrderSubmit }) => {
        const { total, url } = quoteParameters.quote;
        return (
          <div className="main-order">
            <div className="main-order-price">
              <div className="main-order-price_text">
                <span>{priceText}&nbsp;</span>
              </div>
              <div className="main-order-price_number">
                <span className="price">${handleFormatNumber(total)}</span>
              </div>
            </div>
            <div className="main-order-button">
              <a
                target="_blank"
                href={url}
                rel="noopener noreferrer"
                onClick={e => sendEventAndRedirectOnOrderSubmit(e, url)}
              >
                {dict('Place Shipping Order')}
              </a>
            </div>
          </div>
        );
      }}
    </MainConsumer>
  );
};

export default OrderPart;
