import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import dict from '../../../services/i18n/dict';

import SelectDomestic from './domestic';
import SelectInternational from './international';
import { Context } from '../../context';
import { deliveryTypes } from '../../constants';

import './styles.scss';

const components = {
  [deliveryTypes.domestic]: SelectDomestic,
  [deliveryTypes.international]: SelectInternational
};

const Shipping = ({ handleClose }) => {
  const { type } = useContext(Context);
  // TODO: add scenario for CA/MX
  const defaultShippingType = components[type]
    ? type
    : deliveryTypes.international;
  const [shippingType, onChange] = useState(defaultShippingType);
  const ShippingComponent = components[shippingType];
  const ShippingComponentProps = { handleClose };

  function handleChange(event) {
    const { value } = event.target;

    onChange(value);
  }

  return (
    <React.Fragment>
      <div className="shipping-group__title">{dict('Shipping Option:')}</div>
      <div className="shipping-group">
        <div className="shipping-group__options">
          <label htmlFor={deliveryTypes.domestic} className="radio">
            <input
              type="radio"
              name="shipping"
              id={deliveryTypes.domestic}
              value={deliveryTypes.domestic}
              checked={shippingType === deliveryTypes.domestic}
              onChange={handleChange}
              className="radio__input"
            />
            <span className="radio__box" />
            {dict('Domestic')} <span className="hide-desktop">Shipping</span>
          </label>
          <label htmlFor={deliveryTypes.international} className="radio">
            <input
              type="radio"
              name="shipping"
              id={deliveryTypes.international}
              value={deliveryTypes.international}
              checked={shippingType === deliveryTypes.international}
              onChange={handleChange}
              className="radio__input"
            />
            <span className="radio__box" />
            {dict('International')}{' '}
            <span className="hide-desktop">Shipping</span>
          </label>
        </div>
        <div className="shipping-group__value">
          {React.cloneElement(<ShippingComponent />, ShippingComponentProps)}
        </div>
      </div>
    </React.Fragment>
  );
};

Shipping.defaultProps = {
  handleClose: () => {}
};

Shipping.propTypes = {
  handleClose: PropTypes.func
};

export default Shipping;
