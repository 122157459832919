import React, { Component } from 'react';
import ModalWindow from '../ModalWindow';
import * as Sentry from '@sentry/browser';
import ErrorDataLoading from '../ErrorDataLoading';

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    this.props.toggleBoundaryError();
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  }
  render() {
    if (this.props.hasError) {
      return (
        <ModalWindow domNode={document.querySelector('#modal')}>
          <ErrorDataLoading />
        </ModalWindow>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
