import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import {
  TypeDomestic,
  TypeInternational,
  TypeCanadaMexico
} from '../../constant';
import UrlData from '../../services/url-data';

import './InternationalShipping.css';

const { theme } = UrlData;

const InternationalShipping = () => {
  return (
    <MainConsumer>
      {({ typeShipping, toggleTypeShipping }) => {
        return theme && theme === 'banner250' ? (
          <div className="main-international-shipping">
            <label className="shipping-label">
              <input
                type="radio"
                id="domestic"
                name="shipping"
                value="domestic"
                className="shipping-radio"
                checked={typeShipping === TypeDomestic}
                onChange={toggleTypeShipping}
              />
              <span className="shipping-text">{dict('Domestic Shipping')}</span>
            </label>
            <label className="shipping-label">
              <input
                type="radio"
                id="international"
                name="shipping"
                value="international"
                className="shipping-radio"
                checked={
                  typeShipping === TypeInternational ||
                  typeShipping === TypeCanadaMexico
                }
                onChange={toggleTypeShipping}
              />
              <span className="shipping-text">
                {dict('International Shipping')}
              </span>
            </label>
          </div>
        ) : (
          <div
            className="main-international-shipping"
            onClick={toggleTypeShipping}
          >
            <button type="button" className="international-shipping-link">
              {typeShipping === TypeDomestic ? (
                <span className="international-shipping-text">
                  {dict('International Shipping')}?
                </span>
              ) : (
                <span className="international-shipping-text">
                  {dict('Domestic Shipping')}?
                </span>
              )}
            </button>
          </div>
        );
      }}
    </MainConsumer>
  );
};

export default InternationalShipping;
