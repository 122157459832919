import translations from './translation';
import getUserLang from './get-user-lang';
import { handleRequestError } from '../errorHandler';
import UrlData from '../url-data';

const { theme } = UrlData;
const browserLang = getUserLang();
const dict = (
  dict,
  defaultLang = 'en',
  lang = browserLang || defaultLang
) => key => {
  try {
    const res = dict[key][lang];

    if (res && theme === 'banner250') {
      return res;
    }

    return dict[key][defaultLang];
  } catch (error) {
    console.log(`There is no key "${key}" for ${lang} lang`);
    handleRequestError(error, {
      key,
      lang
    });
    return '';
  }
};

export default dict(translations);
