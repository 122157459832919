import React, { useContext, useState } from 'react';

import getDestinationTitle from '../utils/get-destination-title';
import getLotTitle from '../utils/get-lot-title';
import isMobile from '../utils/is-mobile';
import { Context } from '../context';
import { deliveryTypes } from '../constants';

import ActionBar from '../action-bar';
import Topic from '../topic';
import SelectGroup from '../select-group';
import SelectLot from '../select-group/lot';
import SelectShipping from '../select-group/shipping';

const Content = () => {
  const { destination, lot, lots, type } = useContext(Context);
  const [showSelectLot, toggleSelectLot] = useState();
  const [showSelectShipping, toggleSelectShipping] = useState(!destination);
  const showChangeLots = Object.keys(lots).length > 1;

  function handleToggleSelectLot() {
    toggleSelectLot(!showSelectLot);
  }

  function handleToggleSelectShipping() {
    if (destination) {
      toggleSelectShipping(!showSelectShipping);
    }
  }

  function getExtendedDestinationTitle() {
    const title = getDestinationTitle(type, destination);
    const { us_port } = destination || {};

    if (type === deliveryTypes.international && us_port && !isMobile) {
      return (
        <React.Fragment>
          {us_port}{' '}
          <img
            className="icon icon--arrow"
            src="img/arrow.svg"
            alt="arrow"
            width="11"
            height="11"
          />{' '}
          {title}
        </React.Fragment>
      );
    }

    return title;
  }

  const extendedDestinationTitle = getExtendedDestinationTitle();

  return (
    <React.Fragment>
      {showSelectShipping && (
        <SelectGroup handleClose={handleToggleSelectShipping}>
          <SelectShipping />
        </SelectGroup>
      )}
      {showSelectLot && (
        <SelectGroup handleClose={handleToggleSelectLot}>
          <SelectLot />
        </SelectGroup>
      )}

      <div className="banner__form">
        <Topic
          className="banner__vehicle"
          title="Vehicle"
          value={getLotTitle(lot)}
          onChange={showChangeLots ? handleToggleSelectLot : undefined}
        />
        {extendedDestinationTitle ? (
          <Topic
            title="To:&nbsp;"
            value={getExtendedDestinationTitle()}
            onChange={handleToggleSelectShipping}
          />
        ) : (
          <div className="topic">
            <button
              className="btn btn--secondary btn--tiny"
              onClick={handleToggleSelectShipping}
            >
              Set Destination
            </button>
          </div>
        )}
      </div>
      <ActionBar />
    </React.Fragment>
  );
};

export default Content;
