import React, { Component, Fragment } from 'react';

import dict from '../../services/i18n/dict';
import CarData from './CarDataStock.js';
import LocationCustomerData from './LocationCustomerData.js';
import SelectComponent from '../SelectComponent';
import AutocompleteComponent from '../AutocompleteComponent';
import { MainConsumer } from '../../contexts/ContextMain';
import UrlData from '../../services/url-data';
import {
  TypeDomestic,
  TypeInternational,
  TypeCanadaMexico
} from '../../constant';
//style
let options;
let portsOptions;

const { theme } = UrlData;

if (theme === 'bannerstock') {
  import('./SelectLocationPage.css');
}

class SelectLocationPage extends Component {
  state = { isGooglePlacesActive: false, canadaFirstRender: false };

  handleSubmit = e => {
    const { handleGetQuoteInter } = this.props;
    e.preventDefault();
    handleGetQuoteInter();
  };

  setGoogleDropPosition = value => {
    if (value.length === 0) {
      const containerArr = [...document.querySelectorAll('.pac-container')];
      if (containerArr.length) {
        const container = containerArr[containerArr.length - 1];
        const newTop = parseFloat(container.style.top) - 17;
        container.style.top =
          value.length === 0 ? `${newTop}px` : container.style.top;
      }
    } else {
      return;
    }
  };

  setSelectPosition = () => {
    this.setState(prev => ({
      isGooglePlacesActive: !prev.isGooglePlacesActive,
      canadaFirstRender: false
    }));
  };

  selectToggleFocus = cb => {
    cb();
    this.setSelectPosition();
  };

  setGooglePlacesActive = value => {
    this.setState({ isGooglePlacesActive: value });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCanadaMexico !== this.props.isCanadaMexico &&
      this.props.isCanadaMexico
    ) {
      this.setState({
        canadaFirstRender: true
      });
    }
  }

  render() {
    return (
      <MainConsumer>
        {({
          countriesList,
          toggleTypeShipping,
          typeShipping,
          isDestinationStock,
          handleOnChangeInputDestination,
          handleOnChangeSelectPort,
          currentPortTypeB,
          handleOnChangeSelectCountry,
          listDestinationsPorts,
          isCanadaMexico,
          toggleModalStockTransit,
          handleDeleteUsaDestination,
          isStockDomesticInputFocus,
          isStockIntInputFocus
        }) => {
          if (countriesList) {
            options = countriesList
              .filter(item => item.name !== 'USA')
              .map(country => ({
                value: country.id,
                label: country.name
              }));
          }

          if (listDestinationsPorts) {
            portsOptions = listDestinationsPorts.map(port => ({
              value: port.id,
              label: port.name
            }));
          }

          const filterOption = (option, inputValue) => {
            const { label } = option;
            return label.toLowerCase().startsWith(inputValue.toLowerCase());
          };

          const destinationLocalStorage = localStorage.getItem(
            'isDestinationStock'
          )
            ? JSON.parse(localStorage.getItem('isDestinationStock'))
            : '';

          const isDestination =
            destinationLocalStorage.isDestinationStock || isDestinationStock;
          return (
            <div className="wrapper">
              <CarData />
              {isDestination ? (
                <LocationCustomerData />
              ) : (
                <Fragment>
                  <div className="data-shipping">
                    <p className="data-shipping-title">Shipping Option</p>
                    <div className="shipping-radio domestic">
                      <input
                        type="radio"
                        id="domestic"
                        name="shipping"
                        value="domestic"
                        checked={typeShipping === TypeDomestic}
                        onChange={toggleTypeShipping}
                      />
                      <label
                        htmlFor="domestic"
                        className="shipping-radio-label"
                      >
                        Domestic
                      </label>
                    </div>
                    <div className="shipping-radio">
                      <input
                        type="radio"
                        id="international"
                        name="shipping"
                        value="international"
                        checked={
                          typeShipping === TypeInternational ||
                          typeShipping === TypeCanadaMexico
                        }
                        onChange={toggleTypeShipping}
                      />
                      <label
                        htmlFor="international"
                        className="shipping-radio-label"
                      >
                        International
                      </label>
                    </div>
                  </div>
                  <div
                    className={`data-destination-input ${this.state
                      .isGooglePlacesActive && 'active'} ${
                      typeShipping === TypeDomestic && isStockDomesticInputFocus
                        ? 'domestic-input-stock'
                        : typeShipping !== TypeDomestic && isStockIntInputFocus
                        ? 'international-input-stock'
                        : ''
                    }`}
                  >
                    <div
                      className={`data-valid__border ${typeShipping !==
                        TypeDomestic && 'non-domestic'}`}
                    >
                      <form
                        className="form-input data-valid-form"
                        onSubmit={this.handleSubmit}
                      >
                        {typeShipping === TypeDomestic ? (
                          <Fragment>
                            <AutocompleteComponent
                              onPlaceSelected={handleOnChangeInputDestination}
                              placeholder="Enter new destination in USA"
                              setGooglePlacesActive={this.setGooglePlacesActive}
                              setGoogleDropPosition={this.setGoogleDropPosition}
                            />

                            {isStockDomesticInputFocus && (
                              <img
                                onClick={handleDeleteUsaDestination}
                                src="./img/cross.svg"
                                alt="cross"
                              />
                            )}
                          </Fragment>
                        ) : isCanadaMexico ? (
                          <SelectComponent
                            placeholder={dict('Choose Your Port')}
                            options={portsOptions}
                            onChange={handleOnChangeSelectPort}
                            value={currentPortTypeB}
                            canadaFirstRender={this.state.canadaFirstRender}
                            openMenuOnFocus
                            selectToggleFocus={this.selectToggleFocus}
                          />
                        ) : (
                          <SelectComponent
                            placeholder={dict('Choose Your Country')}
                            options={options}
                            onChange={handleOnChangeSelectCountry}
                            filterOption={filterOption}
                            selectToggleFocus={this.selectToggleFocus}
                          />
                        )}
                        <label
                          className={`stock-label ${isStockIntInputFocus &&
                            'active'}`}
                        >
                          {isStockIntInputFocus && isCanadaMexico
                            ? 'Choose Port'
                            : isStockIntInputFocus && !isCanadaMexico
                            ? 'Choose Country'
                            : 'Zip Code or Address'}
                        </label>
                      </form>
                    </div>
                  </div>
                </Fragment>
              )}

              <div
                onClick={toggleModalStockTransit}
                className="form-stock-lot-option header-option"
              >
                <span className="mark-question">?</span>
              </div>
            </div>
          );
        }}
      </MainConsumer>
    );
  }
}

export default SelectLocationPage;
