import React, { useContext } from 'react';
import { Context } from '../context';

import './styles.scss';

const Thumb = () => {
  const { lot: { image_url } = {} } = useContext(Context);

  return (
    <div className="thumb">{image_url && <img src={image_url} alt="" />}</div>
  );
};

export default Thumb;
