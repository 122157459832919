export const TypeDomestic = 'D';
export const TypeInternational = 'I';
export const TypeCanadaMexico = 'B';
export const CountryUS = 'US';
export const CountryCA = 'CA';
export const CountryMX = 'MX';

export const bannerThemeList = ['banner250', 'banner144', 'bannerstock'];
export const destinationTrackingList = {
  SB: process.env.REACT_APP_SB_TRACKING_ENDPOINT
};

export const sbBannerVehicleTypes = [
  'TRUCK',
  'TRAILER',
  'BUS',
  'FARMEQUIPMENT',
  'HEAVYEQUIPMENT',
  'TRAVELTRAILER',
  'BOAT',
  'EMERGENCYEQUIPMENT',
  'Other'
];

export const TOKENS = {
  SB: 'JA5MQF',
  IAAI_LOT: 'VEO8CJ'
};
