import requests from './requests';

const Quote = {
  get(params) {
    return requests.get('/quote', {
      params
    });
  }
};

export default Quote;
