import React from 'react';

import dict from '../../services/i18n/dict';
import './Loader250.css';

const Loader250 = () => {
  return (
    <div className="loader250">
      <div className="loader250-main" />
      <div className="loader250-bottom">
        <div className="loader250-bottom-info">
          <div className="loader250-bottom-title" />
          <div className="loader250-bottom-price" />
        </div>
        <div className="loader250-bottom-button" />
      </div>
      <div className="loader250-spinner-block">
        <div className="loader" />{' '}
        <p className="loader250-text">{dict('Loading')}...</p>
      </div>
    </div>
  );
};

export default Loader250;
