import React from 'react';

import dict from '../../services/i18n/dict';
import './Loader144.css';

const Loader144 = () => {
  return (
    <div className="loader144">
      <div className="loader144-main" />
      <div className="loader144-bottom">
        <div className="loader144-bottom-info">
          <div className="loader144-bottom-title" />
          <div className="loader144-bottom-price" />
        </div>
        <div className="loader144-bottom-button" />
      </div>
      <div className="loader144-spinner-block">
        <div className="loader" />
        <p className="loader250-text">{dict('Loading')}...</p>
      </div>
    </div>
  );
};

export default Loader144;
