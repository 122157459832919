import React from 'react';

import dict from '../../services/i18n/dict';

import './styles.scss';

const Loader = () => {
  return (
    <div className="loader2">
      <div className="loader2__spinner" />
      <div className="loader2__label">{dict('Loading')}...</div>
    </div>
  );
};

export default Loader;
