export const deliveryTypes = {
  domestic: 'D',
  international: 'I',
  canadaOrMexico: 'B'
};

export const countries = {
  CA: 'CA',
  MX: 'MX',
  US: 'US'
};

export const auctionTypes = {
  copart: 1,
  iaa: 2
};

export function checkIsCAorMX(country) {
  return country === countries.CA || country === countries.MX;
}
