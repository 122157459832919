import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import { TypeInternational } from '../../constant';

import './TransitPart.css';

const TransitPart = () => {
  return (
    <MainConsumer>
      {({ toggleModalInfo, quoteParameters, typeShipping }) => {
        return (
          <div
            className={
              typeShipping === TypeInternational
                ? 'main-transit main-transit-international'
                : 'main-transit'
            }
          >
            <div className="main-transit-container">
              <span className="main-transit-text">
                {typeShipping === TypeInternational
                  ? dict('Sailing Time')
                  : dict('Transit Time')}
              </span>
              <span className="main-transit-days">
                {typeShipping === TypeInternational
                  ? quoteParameters.transit.ocean.replace('days', dict('Days'))
                  : quoteParameters.transit.ground}
                {/*{dict('Days', count: 'X')}*/}
              </span>
              <div onClick={toggleModalInfo} className="header-option">
                <span className="mark-question">?</span>
              </div>
            </div>
          </div>
        );
      }}
    </MainConsumer>
  );
};

export default TransitPart;
