import React from 'react';

import dict from '../../services/i18n/dict';
import ModalWindow from '../ModalWindow';
import { MainConsumer } from '../../contexts/ContextMain';

import './BannerStockInfo.css';

const BannerStockInfo = () => {
  return (
    <ModalWindow domNode={document.querySelector('#modal')}>
      <MainConsumer>
        {({ toggleModalStockInfo }) => (
          <div className="banner-stock-info">
            <div className="banner-stock-info-logo">
              <img src="img/eh-stock-about.svg" alt="logo-banner-info" />
            </div>
            <div className="banner-stock-info-text">
              {dict('EasyHaul.com is an independent...')}
            </div>
            <div
              onClick={toggleModalStockInfo}
              className="close-window-stock-info"
            >
              <svg
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 6 6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.25 0L6 .75.75 6 0 5.25 5.25 0z" fill="#000" />
                <path
                  d="M0 .75L.75 0 6 5.25 5.25 6 2.625 3.375 0 .75z"
                  fill="#000"
                />
              </svg>
            </div>
          </div>
        )}
      </MainConsumer>
    </ModalWindow>
  );
};

export default BannerStockInfo;
