/**
 *
 * @param {number} num
 * @returns {string}
 */
export default function formatDecimal(num) {
  if (typeof num !== 'number') {
    console.warn(`${num} is not a number in formatDecimal function`);
  }

  return `$${num.toLocaleString('en-US')}`;
}
