import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';

const CarData = () => {
  return (
    <MainConsumer>
      {({ lotData, handleChangeStock }) => {
        let lot;
        const lotDataLocalStorage = localStorage.getItem('lotDataLocalStorage')
          ? JSON.parse(localStorage.getItem('lotDataLocalStorage'))
          : '';

        lot = lotDataLocalStorage || lotData;

        const {
          make,
          model,
          year,
          lot_number,
          auction_location_state_code,
          auction_location_city
        } = lot;
        return (
          <div className="data">
            <p className="data-item ellipsis">
              <span className="label">{dict('Vehicle')}: </span>
              <strong>{`${year} ${make} ${model}`}</strong>
            </p>
            <p className="data-item ellipsis">
              <span className="label">{dict('Stock #')}: </span>
              {`${lot_number} `}
              <button onClick={handleChangeStock} type="button">
                Change
              </button>
            </p>
            <p className="data-item ellipsis">
              <span className="label">{dict('Location')}: </span>
              <span>{`${auction_location_city}, ${auction_location_state_code}`}</span>
            </p>
          </div>
        );
      }}
    </MainConsumer>
  );
};

export default CarData;
