import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import {
  TypeDomestic,
  TypeInternational,
  TypeCanadaMexico
} from '../../constant';
import './InputPage.css';
import GooglePlacesAutoComplete from '../GooglePlacesAutocomplete';

//local value for component
let options;
let portsOptions;
class InputPage extends Component {
  handleSubmit = e => {
    const { handleGetQuoteInter } = this.props;
    e.preventDefault();
    handleGetQuoteInter();
  };

  render() {
    const customSelectStyles = {
      container: (styles, { selectProps }) => {
        let newStyles = {};

        if (selectProps.menuIsOpen) {
          newStyles = {
            transform: 'translateY(-30px)'
          };
        }

        return {
          ...styles,
          ...newStyles
        };
      }
    };
    const customAsyncSelectStyles = {
      container: (styles, { options }) => {
        let newStyles = {};

        if (options.length) {
          newStyles = {
            transform: 'translateY(-30px)'
          };
        }

        return {
          ...styles,
          ...newStyles
        };
      }
    };

    return (
      <MainConsumer>
        {({
          typeShipping,
          countriesList,
          isCanadaMexico,
          currentPortTypeB,
          handleOnChangeInputDestination,
          handleOnChangeSelectCountry,
          handleOnChangeSelectPort,
          isError,
          listDestinationsPorts,
          theme
        }) => {
          if (countriesList) {
            options = countriesList.map(country => ({
              value: country.id,
              label: country.name
            }));
          }
          if (listDestinationsPorts) {
            portsOptions = listDestinationsPorts.map(port => ({
              value: port.id,
              label: port.name
            }));
          }
          const filterOption = (option, inputValue) => {
            const { label } = option;
            return label.toLowerCase().startsWith(inputValue.toLowerCase());
          };
          const isBanner250 = theme === 'banner250';

          return (
            <div className="main-delivery-input">
              <form className="form-input" onSubmit={this.handleSubmit}>
                {typeShipping === TypeDomestic ? (
                  <GooglePlacesAutoComplete
                    onChange={handleOnChangeInputDestination}
                    className={classNames('select', 'select--places', {
                      'input-autocomplete-error': isError
                    })}
                    classNamePrefix="eh-select"
                    placeholder={dict('Enter new destination in US')}
                    styles={!isBanner250 && customAsyncSelectStyles}
                  />
                ) : isCanadaMexico ? (
                  <Select
                    className={
                      isError
                        ? 'select input-autocomplete-error'
                        : 'select select-port'
                    }
                    placeholder={dict('Choose Your Port')}
                    classNamePrefix="eh-select"
                    options={portsOptions}
                    onChange={handleOnChangeSelectPort}
                    value={currentPortTypeB}
                    styles={!isBanner250 && customSelectStyles}
                  />
                ) : (
                  <Select
                    className={
                      isError ? 'select input-autocomplete-error' : 'select'
                    }
                    placeholder={dict('Choose Your Country')}
                    classNamePrefix="eh-select"
                    options={options}
                    filterOption={filterOption}
                    onChange={handleOnChangeSelectCountry}
                    styles={!isBanner250 && customSelectStyles}
                  />
                )}
              </form>
              {isError && (
                <div className="text-danger">
                  {typeShipping === TypeDomestic
                    ? dict('Please, enter a valid US address or zip code')
                    : typeShipping === TypeInternational
                    ? dict('Please, choose your country')
                    : typeShipping === TypeCanadaMexico
                    ? dict('Please, choose your port')
                    : ''}
                </div>
              )}
            </div>
          );
        }}
      </MainConsumer>
    );
  }
}

export default InputPage;
