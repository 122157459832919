import React, { useContext } from 'react';

import dict from '../../../../services/i18n/dict';

import get from '../../../../utils/get';
import isMobile from '../../../utils/is-mobile';
import { Location } from '../../../services/api';
import { Context } from '../../../context';
import { deliveryTypes } from '../../../constants';

import GooglePlacesAutoComplete from '../../../../Components/GooglePlacesAutocomplete';

const SelectDomestic = () => {
  const { lot, pullAndSetQuote } = useContext(Context);
  const customStyles = {
    control: (styles, state) => {
      const optionsAreLoaded = state.options.length;
      let newStyles = {};

      if (optionsAreLoaded) {
        newStyles = {
          borderRadius: '4px  4px 0 0  !important;',
          borderBottom: 'none',
          transform: isMobile ? 'translateY(-20px)' : 'translateY(-18px)'
        };
      }

      return {
        ...styles,
        ...newStyles
      };
    }
  };

  function onChange(googlePlace) {
    if (!googlePlace.geometry) {
      return;
    }

    const { lat, lng } = get(googlePlace, 'geometry.location');
    const { address_components = [] } = googlePlace;
    const zipComponent = address_components.find(({ types = [] }) =>
      types.some(type => type === 'postal_code')
    );
    const zip = get(zipComponent, 'long_name');

    if (zip) {
      pullAndSetQuote(
        deliveryTypes.domestic,
        {
          zip
        },
        lot
      );
    } else {
      Location.get(lat(), lng()).then(destination => {
        pullAndSetQuote(deliveryTypes.domestic, destination, lot);
      });
    }
  }

  return (
    <div>
      <GooglePlacesAutoComplete
        classNamePrefix="select"
        maxMenuHeight={isMobile ? 30 : 40}
        onChange={onChange}
        placeholder={dict('Enter Zip Code or Address')}
        styles={customStyles}
      />
    </div>
  );
};

export default SelectDomestic;
