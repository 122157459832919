/*
on = false || true
0 < value < 1
maxAge (number): relative max age of the cookie from when the client receives it in second
maxAge default - 3600 (1h)
*/

export default {
  withCap: {
    on: true,
    value: 0.5,
    maxAge: 3600 /* 1h */
  }
};
