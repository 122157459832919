import React, { PureComponent } from 'react';
//constants
import {
  TypeDomestic,
  TypeCanadaMexico,
  TypeInternational,
  CountryUS,
  CountryCA,
  CountryMX,
  sbBannerVehicleTypes,
  TOKENS
} from '../../constant';
//services
import {
  getGeoAutodetection,
  getGeolocation,
  getListOfCountry,
  getListDestinationsPort
} from '../../services/api/location-requests';
import { getQuote } from '../../services/api/quote-calculation';
import { getLotData } from '../../services/api/lot';
import UrlData from '../../services/url-data';
import { sendEvent } from '../../services/statistics';
import get from '../../utils/get';
import LocalStorage from '../../utils/local-storage';
//context
const { Provider, Consumer: MainConsumer } = React.createContext('');
//local variables
let countryState;
let countriesList;
let quoteParameters;
const {
  country,
  lot,
  make,
  model,
  origin_zip,
  vin,
  year,
  zip,
  auction = 2,
  lot_number,
  theme: themeFromQuery,
  vehicle_type,
  token
} = UrlData;

class MainProvider extends PureComponent {
  state = {
    hasError: false,
    lotData: {},
    isValid: false,
    isLoading: true,
    isCanadaMexico: false,
    listDestinationsPorts: [],
    currentPortTypeB: '',
    isCustomQuote: false,
    typeShipping: '',
    countriesList: [],
    loadingQuote: false,
    showModalAbout: false,
    showModalContacts: false,
    showModalInfo: false,
    changeDestination: false,
    countryState: {},
    quoteParameters: {},
    newPlaceFullData: null,
    newCountryFullData: null,
    errorClient: false,
    errorLoadingData: false,
    lot: lot,
    vin: vin,
    origin_zip: origin_zip,
    year: year,
    make: make,
    model: model,
    isError: false,
    lotNumber: '',
    validError: false,
    validErrorText: '',
    destinationStock: '',
    isDestinationStock: false,
    quoteParametersStock: {},
    stockInputValue: false,
    showModalStockInfo: false,
    validButtonDisabled: false,
    showModalStockTransit: false,
    usaDestination: '',
    isStockInputFocus: false,
    isStockDomesticInputFocus: false,
    isStockIntInputFocus: false,
    theme: themeFromQuery
  };

  //Stock
  handleGetLotData = async e => {
    try {
      e.preventDefault();
      const { lotNumber } = this.state;
      if (this.validationLotNumber(lotNumber)) {
        await this.setState({
          validButtonDisabled: true
        });
        const lot = await getLotData(auction, lotNumber);
        const lotData = lot.data;
        const typeShipping = countryState.type;
        this.setState({
          lotData,
          isValid: true,
          typeShipping,
          validButtonDisabled: false,
          isCustomQuote: false
        });
      } else {
        this.setState({
          validError: true,
          validErrorText: 'Invalid stock number',
          validButtonDisabled: false
        });
      }
    } catch (error) {
      console.error(error, 'error');
      this.setState({
        validErrorText: 'Wrong stock number',
        validError: true,
        validButtonDisabled: false
      });
    }
  };

  handleGetQuoteStock = () => {
    this.setState({
      isDestinationStock: true,
      quoteParametersStock: { ...quoteParameters }
    });
  };

  handleChangeStock = () => {
    LocalStorage.remove('isValidLocalStorage');
    LocalStorage.remove('isDestinationStock');
    LocalStorage.remove('lotDataLocalStorage');
    LocalStorage.remove('quoteParameters');
    LocalStorage.remove('isCustomQuoteLocalStorage');
    LocalStorage.remove('destination');

    this.setState(
      {
        isValid: false,
        lotNumber: '',
        isDestinationStock: false,
        usaDestination: '',
        isCustomQuote: false,
        stockInputValue: false,
        isStockInputFocus: false,
        isStockIntInputFocus: false
      },
      () => {
        this.forceUpdate();
      }
    );
  };
  handleChangeDestinationBannerStock = () => {
    LocalStorage.remove('isDestinationStock');
    LocalStorage.remove('quoteParameters');
    LocalStorage.remove('isCustomQuoteLocalStorage');
    this.setState({
      isDestinationStock: false,
      quoteParametersStock: {},
      usaDestination: '',
      isCustomQuote: false,
      isStockInputFocus: false,
      isStockIntInputFocus: false
    });
  };

  validationLotNumber = lotNumber => {
    const leng = lotNumber.toString().length;
    const num = Number(lotNumber);
    if (isFinite(num) && typeof num === 'number') {
      if (leng > 5 && leng < 10) {
        this.setState({
          validErrorText: ''
        });
        return true;
      } else {
        this.setState({
          validErrorText: 'Wrong length stock number'
        });
        return false;
      }
    } else {
      this.setState({
        validErrorText: 'Stock number should be a number'
      });
      return false;
    }
  };

  handleSetLotNumber = e => {
    const { value } = e.target;
    this.setState(prev => ({
      lotNumber: value,
      stockInputValue: value.length > 0,
      validError:
        prev.validError && value.length === 0
          ? !prev.validError
          : prev.validError
    }));
  };

  handleDeleteInputStock = () => {
    this.setState({
      lotNumber: '',
      validErrorText: '',
      validError: false,
      stockInputValue: false
    });
  };

  handleDeleteDestinationStock = () => {
    this.setState({
      destinationStock: ''
    });
  };

  toggleModalStockInfo = () => {
    this.setState(prevState => ({
      showModalStockInfo: !prevState.showModalStockInfo
    }));
  };

  toggleModalStockTransit = () => {
    this.setState(prevState => ({
      showModalStockTransit: !prevState.showModalStockTransit,
      isDestination: false
    }));
  };

  toggleCustomQuoteStock = () => {
    LocalStorage.remove('isCustomQuoteLocalStorage');
    this.setState(prevState => ({
      isCustomQuote: !prevState.isCustomQuote,
      isStockIntInputFocus: false
    }));
  };

  //Regular
  handleOnChangeInputDestination = async place => {
    if (place.geometry) {
      const { lat, lng } = place.geometry.location;
      const locationLat = lat();
      const locationLng = lng();

      const { address_components = [] } = place;
      const zipComponent = address_components.find(({ types = [] }) =>
        types.some(type => type === 'postal_code')
      );
      const zip = get(zipComponent, 'long_name');

      let GeolocationData = {
        data: {
          zip
        }
      };

      if (!zip) {
        GeolocationData = await getGeolocation(locationLat, locationLng); // get new place data: {zip, city, state, state_code}
      }

      const newPlaceFullData = GeolocationData.data;

      this.setState({ newPlaceFullData }, () => {
        this.handleGetQuoteUSA();
      });
    }
  };

  handleOnChangeSelectCountry = async country => {
    const selectCountry = countriesList.filter(el => el.name === country.label);
    let newCountryFullData = selectCountry[0];
    let { iso_2 } = newCountryFullData;

    if (iso_2 === CountryCA || iso_2 === CountryMX) {
      const responseListDestinationsPort = await getListDestinationsPort(
        selectCountry[0].id
      );
      const listDestinationsPorts = responseListDestinationsPort.data;

      return this.setState(() => ({
        typeShipping: newCountryFullData.type,
        isCanadaMexico: true,
        listDestinationsPorts,
        newCountryFullData
      }));
    }

    this.setState(
      {
        newCountryFullData,
        typeShipping: newCountryFullData.type
      },
      () => {
        this.handleGetQuoteInter();
      }
    );
  };

  handleOnChangeSelectPort = async currentPortTypeB => {
    this.setState({ currentPortTypeB }, () => {
      this.handleGetQuoteInter();
    });
  };

  addParamsForStockBanner = quoteParameters => {
    LocalStorage.set(
      'isDestinationStock',
      JSON.stringify({ isDestinationStock: true })
    );
    LocalStorage.set('quoteParameters', JSON.stringify(quoteParameters));
  };

  handleGetQuoteUSA = async () => {
    try {
      this.setState({
        isLoading: true
      });
      const {
        newPlaceFullData,
        origin_zip,
        vin,
        changeDestination,
        lotData,
        theme
      } = this.state;

      const lotDataLocalStorage = localStorage.getItem('lotDataLocalStorage')
        ? JSON.parse(localStorage.getItem('lotDataLocalStorage'))
        : '';

      let lot = lotDataLocalStorage || lotData;

      const {
        auction: auctionId,
        auction_location_zip,
        lot_number: lotId,
        vin: lotVin
      } = lot;

      let responseQuote;
      if (newPlaceFullData && newPlaceFullData.zip) {
        const destination_zip = newPlaceFullData.zip;

        if (theme !== 'bannerstock') {
          responseQuote = await getQuote(TypeDomestic, {
            origin_zip,
            destination_zip,
            vin,
            auction,
            lot_number
          }); // get new quote
        } else {
          responseQuote = await getQuote(TypeDomestic, {
            auction_location_zip,
            destination_zip,
            vin: lotVin,
            auction: auctionId,
            lot_number: lotId
          }); // get new stock quote
        }

        if (typeof responseQuote === 'undefined') {
          this.catchGetQuoteUSA(null);
          return;
        }

        quoteParameters = responseQuote.data;
        this.addedQuoteToLS(quoteParameters, countryState);

        if (theme === 'bannerstock') {
          this.addParamsForStockBanner(quoteParameters);
          this.setState({
            countryState,
            isDestinationStock: true,
            quoteParametersStock: quoteParameters,
            isLoading: false,
            isCustomQuote: false,
            newPlaceFullData: null,
            isError: false
          });
        } else {
          this.setState({
            changeDestination: !changeDestination,
            countryState: {
              ...countryState,
              ...newPlaceFullData,
              type: this.state.typeShipping
            },
            isCustomQuote: false,
            quoteParameters,
            isLoading: false,
            newPlaceFullData: null,
            isError: false
          });
        }
      } else {
        return this.setState({
          isLoading: false,
          newPlaceFullData: null,
          isError: true
        });
      }
    } catch (error) {
      this.catchGetQuoteUSA(error);
    }
  };

  catchGetQuoteUSA(error) {
    const { changeDestination, typeShipping, newPlaceFullData } = this.state;
    LocalStorage.set(
      'isCustomQuoteLocalStorage',
      JSON.stringify({
        isCustomQuote: true,
        changeDestination: !changeDestination,
        newPlaceFullData: newPlaceFullData,
        typeShipping: typeShipping
      })
    );
    this.setState({
      isLoading: false,
      changeDestination: !changeDestination,
      isCustomQuote: true,
      isCanadaMexico: false,
      currentPortTypeB: '',
      countryState: {
        ...countryState,
        ...newPlaceFullData,
        type: typeShipping
      }
    });

    if (error) {
      console.error(error, 'error');
    }
  }

  addedQuoteToLS = (quoteParameters, countryState) => {
    const { typeShipping } = this.state;
    if (localStorage.getItem('destination')) {
      LocalStorage.remove('destination');
    }
    let destination = {
      ...quoteParameters.destination,
      type: this.state.typeShipping
    };

    if (typeShipping !== TypeDomestic) {
      destination.country = {
        ...quoteParameters.destination.country,
        ...countryState
      };
    }

    const quoteLocalStorage = JSON.stringify(destination);
    LocalStorage.set('destination', quoteLocalStorage);
  };

  handleGetQuoteInter = async () => {
    try {
      this.setState({
        isLoading: true
      });
      let responseQuote, lot;
      const {
        newCountryFullData,
        origin_zip,
        currentPortTypeB,
        lotData,
        theme
      } = this.state;

      const lotDataLocalStorage = LocalStorage.get('lotDataLocalStorage')
        ? JSON.parse(LocalStorage.get('lotDataLocalStorage'))
        : '';

      lot = lotDataLocalStorage || lotData;

      const {
        auction: auctionId,
        auction_location_zip,
        lot_number: lotNumber,
        vin: lotVin
      } = lot;

      if (newCountryFullData || currentPortTypeB) {
        if (newCountryFullData) {
          countryState = {
            id: newCountryFullData.id,
            type: newCountryFullData.type,
            country: newCountryFullData.iso_2,
            name: newCountryFullData.name
          };
        }
        if (
          countryState.country === CountryCA ||
          countryState.country === CountryMX
        ) {
          if (currentPortTypeB.value) {
            if (theme !== 'bannerstock') {
              responseQuote = await getQuote(TypeCanadaMexico, {
                origin_zip,
                destination: currentPortTypeB.value,
                vin: lotVin,
                auction,
                lot_number
              });
            } else {
              responseQuote = await getQuote(TypeCanadaMexico, {
                auction_location_zip,
                destination: currentPortTypeB.value,
                vin: lotVin,
                auctionId,
                lotNumber
              });
            }
          } else {
            return this.setState({
              isError: true,
              isLoading: false
            });
          }
        } else {
          if (theme !== 'bannerstock') {
            responseQuote = await getQuote(TypeInternational, {
              origin_zip,
              destination_country: countryState.id,
              vin: lotVin || vin,
              auction,
              lot_number
            });
          } else {
            responseQuote = await getQuote(TypeInternational, {
              auction_location_zip,
              destination_country: countryState.id,
              vin: lotVin || vin,
              auctionId,
              lotNumber
            });
          }
        }

        if (typeof responseQuote === 'undefined') {
          this.catchGetQuoteInter(null);
          return;
        }

        quoteParameters = responseQuote.data;
        this.addedQuoteToLS(quoteParameters, countryState);
        if (theme === 'bannerstock') {
          this.addParamsForStockBanner(quoteParameters);
          this.setState({
            countryState,
            isCustomQuote: false,
            isDestinationStock: true,
            quoteParametersStock: quoteParameters,
            newCountryFullData: null,
            isLoading: false,
            isCanadaMexico: false,
            typeShipping: countryState.type,
            currentPortTypeB: ''
          });
        }
        this.setState({
          countryState,
          isCustomQuote: false,
          changeDestination: !this.state.changeDestination,
          quoteParameters,
          newCountryFullData: null,
          isLoading: false,
          isCanadaMexico: false,
          typeShipping: countryState.type,
          currentPortTypeB: ''
        });
      } else {
        return this.setState({
          isError: true,
          isLoading: false
        });
      }
    } catch (error) {
      this.catchGetQuoteInter(error);
    }
  };

  catchGetQuoteInter(error) {
    LocalStorage.set(
      'isCustomQuoteLocalStorage',
      JSON.stringify({
        isCustomQuote: true,
        changeDestination: !this.state.changeDestination,
        countryState,
        typeShipping: this.state.typeShipping
      })
    );
    this.setState({
      isLoading: false,
      changeDestination: !this.state.changeDestination,
      isCustomQuote: true,
      isCanadaMexico: false,
      currentPortTypeB: '',
      countryState
    });

    if (error) {
      console.error(error, 'error');
    }
  }

  handleQuoteCancel = () => {
    return this.setState(prevState => ({
      changeDestination: !prevState.changeDestination,
      newPlaceFullData: null,
      isError: false,
      isCanadaMexico: false,
      typeShipping: countryState.type
    }));
  };

  toggleModalAbout = () => {
    return this.setState(state => ({ showModalAbout: !state.showModalAbout }));
  };
  toggleModalContacts = () => {
    return this.setState(state => ({
      showModalContacts: !state.showModalContacts
    }));
  };
  toggleModalInfo = () => {
    return this.setState(state => ({ showModalInfo: !state.showModalInfo }));
  };
  toggleModalErr = () => {
    return this.setState(state => ({ errorClient: !state.errorClient }));
  };
  toggleBoundaryError = () => {
    return this.setState(state => ({ hasError: !state.hasError }));
  };

  toggleTypeShipping = () => {
    this.setState(state => {
      const type = state.typeShipping === TypeDomestic ? 'I' : 'D';
      return {
        typeShipping: type,
        isCanadaMexico: false,
        isError: false,
        usaDestination: ''
      };
    });
  };

  handleToggleState = property => {
    if (!this.state.hasOwnProperty(property)) {
      console.warn(
        `You can only toggle existing state properties. There is no ${property} in state object`
      );
      return;
    }

    this.setState(prevState => ({
      [property]: !prevState[property]
    }));
  };

  handleQuoteIntenational = async () => {
    try {
      let listDestinationsPorts,
        responseQuote,
        countryType,
        countryId,
        countryName;
      const destinationLocalStorage = localStorage.getItem('destination')
        ? JSON.parse(localStorage.getItem('destination'))
        : '';

      const lotDataLocalStorage = localStorage.getItem('lotDataLocalStorage')
        ? JSON.parse(localStorage.getItem('lotDataLocalStorage'))
        : '';
      if (destinationLocalStorage && destinationLocalStorage.country) {
        countryType = destinationLocalStorage.country.type;
        countryId = destinationLocalStorage.country.id;
        countryName = destinationLocalStorage.country.name;
      }

      const { origin_zip, vin, theme } = this.state;
      const { id, type, name } = this.handlerFilterByCountry(); // get id and type country
      countryState.id = countryId || id;
      countryState.type = countryType || type;
      countryState.name = countryName || name;
      const responseListDestinationsPort = await getListDestinationsPort(
        countryState.id
      );
      listDestinationsPorts = responseListDestinationsPort.data;
      if (
        countryState.country === CountryCA ||
        countryState.country === CountryMX
      ) {
        if (
          localStorage.getItem('destination') &&
          get(lotDataLocalStorage, 'lot_number', lot_number)
        ) {
          const lotLSData = lotDataLocalStorage || {};
          const quoteParams = {
            origin_zip: origin_zip || lotLSData.auction_location_zip,
            destination: destinationLocalStorage.id,
            vin: vin || lotLSData.vin,
            auction,
            lot_number: lot_number || lotLSData.lot_number
          };
          responseQuote = await getQuote(TypeCanadaMexico, quoteParams);

          const quoteParameters = responseQuote.data;

          this.setState({
            isCustomQuote: false,
            countryState,
            quoteParameters,
            countriesList,
            isLoading: false,
            typeShipping: countryState.type,
            isCanadaMexico: false
          });
        } else {
          this.setState({
            isLoading: false,
            changeDestination: !this.state.changeDestination,
            typeShipping: countryState.type,
            isCanadaMexico: true,
            listDestinationsPorts,
            countriesList
          });
        }
      } else {
        if (theme !== 'bannerstock') {
          let typeShipping;
          responseQuote = await getQuote(TypeInternational, {
            origin_zip,
            destination_country: countryState.id,
            vin,
            auction,
            lot_number
          });

          if (destinationLocalStorage) {
            quoteParameters = {
              ...responseQuote.data,
              destination: destinationLocalStorage,
              type: destinationLocalStorage.country.type
            };
            typeShipping = destinationLocalStorage.country.type;
          } else {
            quoteParameters = responseQuote.data;
            typeShipping = countryState.type;
          }

          this.setState({
            isCustomQuote: false,
            countryState: {
              ...countryState,
              type: typeShipping
            },
            quoteParameters,
            countriesList,
            isLoading: false,
            typeShipping,
            isCanadaMexico: false
          });
        } else {
          const paramLocalStorage = JSON.parse(
            localStorage.getItem('destination')
          );

          let params = {
            countryState,
            typeShipping: get(paramLocalStorage, 'type') || countryState.type,
            isCustomQuote: false
          };

          this.setState({
            ...params,
            isLoading: false,
            countriesList,
            isCanadaMexico: false
          });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        isCustomQuote: true,
        isCanadaMexico: false,
        countriesList,
        currentPortTypeB: ''
      });
    }
  };

  countQuoteForAmerica = async data => {
    try {
      let destination_zip, typeShipping;
      const { origin_zip, vin, theme } = this.state;
      countryState = data.data;

      const responseCountries = await getListOfCountry(); // get list of countries
      countriesList = responseCountries.data;

      const paramLocalStorage = localStorage.getItem('destination')
        ? JSON.parse(localStorage.getItem('destination'))
        : '';

      const { id, type } = this.handlerFilterByCountry(); // get id and type country
      countryState.id = id;
      countryState.type = type;
      destination_zip = countryState.zip;

      if (!destination_zip) {
        this.setState(
          {
            typeShipping: TypeDomestic
          },
          this.handleChangeDestination
        );
        return;
      }

      if (theme !== 'bannerstock') {
        const responseQuote = await getQuote(TypeDomestic, {
          origin_zip,
          destination_zip,
          vin,
          auction,
          lot_number
        });
        if (paramLocalStorage) {
          quoteParameters = {
            ...responseQuote.data,
            destination: { zip: paramLocalStorage.zip }
          };
          typeShipping = paramLocalStorage.type;
        } else {
          quoteParameters = responseQuote.data;
          typeShipping = countryState.type;
        }
        this.setState({
          countryState: {
            ...countryState,
            type: typeShipping
          },
          countriesList,
          quoteParameters,
          isLoading: false,
          typeShipping: typeShipping
        });
      } else {
        let params = {
          countryState,
          typeShipping: countryState.type,
          isCustomQuote: false
        };
        const isCustomQuoteLocalStorage = JSON.parse(
          localStorage.getItem('isCustomQuoteLocalStorage')
        );
        if (isCustomQuoteLocalStorage) {
          params = isCustomQuoteLocalStorage;
        }

        this.setState({
          ...params,
          isLoading: false,
          countriesList
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        isCustomQuote: true,
        isCanadaMexico: false,
        currentPortTypeB: ''
      });
    }
  };

  countQuoteForInter = async responseData => {
    try {
      countryState = responseData.data;
      const responseCountries = await getListOfCountry(); // get list of countries
      countriesList = responseCountries.data.filter(
        item => item.name !== 'USA'
      );
      this.handleQuoteIntenational();
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        isCustomQuote: true,
        isCanadaMexico: false,
        currentPortTypeB: ''
      });
    }
  };

  handlerFilterByCountry = () => {
    let countryInfo;
    if (countriesList && countriesList.length > 0) {
      countryInfo = countriesList.find(
        country => country.iso_2 === countryState.country
      );
    } else {
      countryInfo = { id: '', type: '', name: '' };
      // add some ERROR for empty list and show some banner ?
    }
    const { id, type, name } = countryInfo;
    return { id, type, name };
  };

  handleChangeDestination = () =>
    this.setState(state => ({
      changeDestination: !state.changeDestination,
      newPlaceFullData: null,
      isLoading: false
    }));

  handleGetQuoteForAmerica = async () => {
    try {
      if (zip) {
        const responseQuote = await getQuote(TypeDomestic, {
          origin_zip,
          destination_zip: zip,
          vin,
          auction,
          lot_number
        });

        if (typeof responseQuote === 'undefined') {
          this.catchGetQuoteForAmerica(null);
          return;
        }

        quoteParameters = responseQuote.data; // get quote
        this.setState({
          countryState: {
            ...countryState,
            type: countryState.type
          },
          countriesList,
          isCustomQuote: false,
          quoteParameters,
          isLoading: false,
          typeShipping: countryState.type
        });
      } else {
        this.setState({
          isCustomQuote: false,
          countryState,
          countriesList,
          changeDestination: true,
          typeShipping: countryState.type,
          isLoading: false
        });
      }
    } catch (error) {
      this.catchGetQuoteForAmerica(error);
    }
  };

  catchGetQuoteForAmerica(error) {
    this.setState({
      isLoading: false,
      isCustomQuote: true,
      isCanadaMexico: false,
      currentPortTypeB: ''
    });

    if (error) {
      console.error(error, 'error');
    }
  }

  getAllDataForView = async () => {
    try {
      if (country) {
        countryState = { country, zip };
        const responseCountries = await getListOfCountry(); // get list of countries
        countriesList = responseCountries.data;
        const { id, type } = this.handlerFilterByCountry(); // get id and type country
        countryState.id = id;
        countryState.type = type;
        if (country === CountryUS) {
          this.handleGetQuoteForAmerica(country);
        } else {
          this.handleQuoteIntenational();
        }
      } else {
        getGeoAutodetection()
          .then(response => {
            const { country } = response.data;
            if (country === CountryUS) {
              this.countQuoteForAmerica(response);
            } else {
              this.countQuoteForInter(response);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        errorLoadingData: true
      });
    }
  };

  // eslint-disable-next-line
  async sendEventAndRedirectOnOrderSubmit(e, url) {
    e.preventDefault();
    sendEvent({ event: 'eh_widget_cta_click' });
    window.open(url, '_blank');
  }

  toggleInputError = () => {
    this.setState(prev => ({
      isError: !prev.isError
    }));
  };

  handleSetUsaDestination = e => {
    const { value } = e.target;
    this.setState({
      usaDestination: value
    });
  };

  handleDeleteUsaDestination = () => {
    this.setState({
      usaDestination: ''
    });
  };

  toggleStockInputFocus = () => {
    this.setState(prev => ({
      isStockInputFocus: !prev.isStockInputFocus
    }));
  };

  toggleStockDomesticInputFocus = () => {
    this.setState(prev => ({
      isStockDomesticInputFocus: !prev.isStockDomesticInputFocus,
      usaDestination: ''
    }));
  };

  toggleStockIntInputFocus = () => {
    this.setState(prev => ({
      isStockIntInputFocus: !prev.isStockIntInputFocus
    }));
  };

  componentDidMount() {
    const showSbBanner =
      themeFromQuery === 'banner250' &&
      token === TOKENS.IAAI_LOT &&
      sbBannerVehicleTypes.includes(vehicle_type);

    if (showSbBanner) {
      this.setState({
        isLoading: false,
        showSbBanner: true
      });
    } else {
      this.getAllDataForView();
    }
  }

  render() {
    const { children } = this.props;
    const {
      hasError,
      isCanadaMexico,
      currentPortTypeB,
      isLoading,
      isCustomQuote,
      typeShipping,
      loadingQuote,
      showModalAbout,
      showModalContacts,
      showModalInfo,
      showSbBanner,
      changeDestination,
      make,
      model,
      year,
      quoteParameters,
      errorClient,
      errorLoadingData,
      countryState,
      countriesList,
      isError,
      listDestinationsPorts,
      lotNumber,
      lotData,
      isValid,
      validError,
      validErrorText,
      destinationStock,
      isDestinationStock,
      quoteParametersStock,
      stockInputValue,
      validButtonDisabled,
      showModalStockInfo,
      showModalStockTransit,
      usaDestination,
      isStockInputFocus,
      isStockDomesticInputFocus,
      isStockIntInputFocus,
      theme
    } = this.state;

    return (
      <Provider
        value={{
          hasError,
          isCanadaMexico,
          currentPortTypeB,
          isLoading,
          loadingQuote,
          isCustomQuote,
          typeShipping,
          showModalAbout,
          showModalContacts,
          showModalInfo,
          showSbBanner,
          changeDestination,
          make,
          model,
          year,
          quoteParameters,
          errorClient,
          errorLoadingData,
          countryState,
          countriesList,
          isError,
          listDestinationsPorts,
          lotNumber,
          lotData,
          isValid,
          validError,
          validErrorText,
          destinationStock,
          isDestinationStock,
          quoteParametersStock,
          stockInputValue,
          showModalStockInfo,
          validButtonDisabled,
          showModalStockTransit,
          usaDestination,
          isStockInputFocus,
          isStockDomesticInputFocus,
          isStockIntInputFocus,
          toggleModalAbout: this.toggleModalAbout,
          toggleBoundaryError: this.toggleBoundaryError,
          toggleModalInfo: this.toggleModalInfo,
          handleOnChangeInputDestination: this.handleOnChangeInputDestination,
          handleGetQuoteUSA: this.handleGetQuoteUSA,
          handleGetQuoteInter: this.handleGetQuoteInter,
          handleQuoteCancel: this.handleQuoteCancel,
          toggleModalErr: this.toggleModalErr,
          handleOnChangeSelectCountry: this.handleOnChangeSelectCountry,
          handleOnChangeSelectPort: this.handleOnChangeSelectPort,
          toggleTypeShipping: this.toggleTypeShipping,
          toggleInputError: this.toggleInputError,
          handleGetLotData: this.handleGetLotData,
          handleSetLotNumber: this.handleSetLotNumber,
          handleDeleteInputStock: this.handleDeleteInputStock,
          handleDeleteDestinationStock: this.handleDeleteDestinationStock,
          handleGetQuoteStock: this.handleGetQuoteStock,
          handleChangeStock: this.handleChangeStock,
          handleChangeDestination: this.handleChangeDestination,
          handleChangeDestinationBannerStock: this
            .handleChangeDestinationBannerStock,
          toggleModalStockInfo: this.toggleModalStockInfo,
          toggleModalStockTransit: this.toggleModalStockTransit,
          handleSetUsaDestination: this.handleSetUsaDestination,
          handleDeleteUsaDestination: this.handleDeleteUsaDestination,
          handleToggleState: this.handleToggleState,
          toggleStockInputFocus: this.toggleStockInputFocus,
          toggleStockDomesticInputFocus: this.toggleStockDomesticInputFocus,
          toggleStockIntInputFocus: this.toggleStockIntInputFocus,
          toggleCustomQuoteStock: this.toggleCustomQuoteStock,
          sendEventAndRedirectOnOrderSubmit: this
            .sendEventAndRedirectOnOrderSubmit,
          theme,
          toggleModalContacts: this.toggleModalContacts
        }}
      >
        {children}
      </Provider>
    );
  }
}

const TestProvider = Provider;

export { MainProvider, MainConsumer, TestProvider };
