import React, { useEffect } from 'react';
import classNames from 'classnames';

import { MainConsumer } from '../../contexts/ContextMain';
import { sendEvent } from '../../services/statistics';
import { destinationTrackingList } from '../../constant';

import './SalvagebidBanner.css';

const SalvagebidBanner = ({ accent }) => {
  useEffect(() => {
    sendEvent({
      event: 'eh_widget_sb_show',
      trackingURL: destinationTrackingList.SB
    });
  }, []);

  const salvageBidLogoSrc = `img/sb_logo${accent ? '--accent' : ''}.svg`;

  return (
    <article
      className={classNames('salvagebid250', {
        'salvagebid250--accent': accent
      })}
    >
      <header className="salvagebid250__header">
        <div className="salvagebid250__logo-group">
          <img src={salvageBidLogoSrc} alt="badge" height="24" width="72" />
          <div className="salvagebid250__badge">
            <img src="img/badge.svg" alt="badge" />
            <span className="salvagebid250__badge-text">PREMIUM BROKER</span>
          </div>
        </div>
        <h1 className="salvagebid250__title">PRE-BID NOW</h1>
        <div className="salvagebid250__text">
          No dealer license required! <br /> Open to the public
        </div>
      </header>
      <MainConsumer>
        {({ make, model, year }) => {
          const title = [year, make, model].filter(Boolean).join(' ');

          if (!title) {
            return null;
          }

          return <div className="salvagebid250__text-car">{title}</div>;
        }}
      </MainConsumer>
      <a
        className="salvagebid250__button"
        href="https://svc.salvagebid.com/link/v1/r/f36b5188-07b3-44c5-956b-f9d3cc10d157"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          sendEvent({
            event: 'eh_widget_sb_click',
            trackingURL: destinationTrackingList.SB
          })
        }
      >
        Start Bidding Now
      </a>
    </article>
  );
};

export default SalvagebidBanner;
