import React from 'react';

import dict from '../../services/i18n/dict';
import ModalWindow from '../ModalWindow';
import { MainConsumer } from '../../contexts/ContextMain';
import CustomQuoteLink from '../CustomQuoteLink';

import './CustomQuoteStock.css';

const CustomQuoteStock = () => {
  return (
    <ModalWindow domNode={document.querySelector('#modal')}>
      <MainConsumer>
        {({ toggleCustomQuoteStock }) => (
          <div className="custom-quote-wrapp">
            <div className="custom-quote-text">
              <span>{dict('Instant quote is not available')}</span>
              <button
                type="button"
                onClick={toggleCustomQuoteStock}
                className="change-destination"
              >
                {dict('Change Destination')}
              </button>
            </div>
            <CustomQuoteLink />
          </div>
        )}
      </MainConsumer>
    </ModalWindow>
  );
};
export default CustomQuoteStock;
