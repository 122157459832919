import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';

import { checkIsCAorMX, deliveryTypes } from '../../../constants';

import dict from '../../../../services/i18n/dict';
import { Context } from '../../../context';
import get from '../../../../utils/get';
import isMobile from '../../../utils/is-mobile';
import { Location } from '../../../services/api';

// We have 3 scenarios that depend on the country's destination
// Difference based on the additional step with port select
// Canada => Port
// Mexico => Port
// All other countries

const SelectInternational = () => {
  const [country, onChangeCountry] = useState(null);
  const [port, onChangePort] = useState();
  const [ports, setPorts] = useState([]);

  const { pullAndSetQuote, lot } = useContext(Context);
  const isCAorMX = checkIsCAorMX(get(country, 'iso_2'));

  useEffect(() => {
    if (isCAorMX) {
      Location.getCountryPorts(country.id).then(ports => {
        setPorts(ports);
      });
    }
  }, [country, isCAorMX]);

  const { countries } = useContext(Context);

  function handleChangeCountry(country) {
    const isCAorMX = checkIsCAorMX(get(country, 'iso_2'));
    const destination = {
      countryName: country.name,
      countryId: country.id,
      iso_2: country.iso_2
    };

    onChangeCountry(country);

    if (isCAorMX) {
      return;
    }

    pullAndSetQuote(deliveryTypes.international, destination, lot);
  }

  function handleChangePort(port) {
    const destination = {
      ...country,
      ...port,
      countryId: country.id,
      id: port.id,
      countryName: country.name
    };
    onChangePort(port);

    pullAndSetQuote(deliveryTypes.canadaOrMexico, destination, lot);
  }

  function filterCountries({ label }, inputValue) {
    return label.toLowerCase().startsWith(inputValue.toLowerCase());
  }

  if (isCAorMX) {
    return (
      <Select
        classNamePrefix="select"
        className="select-sync"
        defaultMenuIsOpen
        getOptionLabel={port => port.name}
        key="port"
        maxMenuHeight={isMobile ? 30 : 40}
        onChange={handleChangePort}
        options={ports}
        placeholder={dict('Select Port')}
        value={port}
      />
    );
  }

  return (
    <Select
      classNamePrefix="select"
      className="select-sync"
      filterOption={filterCountries}
      getOptionLabel={country => country.name}
      key="country"
      maxMenuHeight={isMobile ? 30 : 40}
      onChange={handleChangeCountry}
      options={countries}
      placeholder={dict('Select Country')}
      value={country}
    />
  );
};

export default SelectInternational;
