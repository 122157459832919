import React, { Fragment } from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import { TypeInternational, TypeCanadaMexico } from '../../constant';

import './DeliveryPart.css';

const DeliveryPart = () => {
  return (
    <MainConsumer>
      {({ handleChangeDestination, countryState }) => (
        <div className="main-delivery">
          <div className="logo-iaai">
            <img src="img/logo-iaai.svg" alt="logo-iaai" />
          </div>
          <div className="main-delivery-content">
            <div
              className={`main-delivery-city ${countryState &&
                countryState.type === TypeInternational &&
                'main-delivery-city-international'}`}
            >
              <MainConsumer>
                {({ theme, quoteParameters }) => {
                  let destinationTypeB;
                  const { name, country, zip } = quoteParameters.destination;
                  const { ground, ocean } = quoteParameters.quote;
                  const { city, state_code } = quoteParameters.origin;
                  const { us_port } = quoteParameters;
                  if (countryState.type === TypeCanadaMexico) {
                    destinationTypeB = quoteParameters.destination.name.split(
                      '-'
                    );
                  }
                  if (countryState && countryState.type === TypeInternational) {
                    return theme === 'banner250' ? (
                      <Fragment>
                        <div className="main-delivery-city_wrap main-delivery-city_wrap-international">
                          <ul className="main-delivery-list">
                            <li className="delivery-from delivery-from-international">
                              <span>{`${city}, ${state_code}`}</span>
                            </li>
                            <li className="delivery-from delivery-from-international delivery-from-port">
                              <span>{us_port.name}</span>
                            </li>
                            <li className="delivery-to delivery-to-international">
                              <span>{`${name}, ${country.iso_2}`}</span>
                            </li>
                          </ul>
                          <div className="delivery-price-block">
                            <p className="delivery-price">{`$${ground.toLocaleString(
                              'en'
                            )}`}</p>
                            <p className="delivery-price delivery-price-ocean">
                              {`$${ocean.toLocaleString('en')}`}
                            </p>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="main-delivery-city_wrap">
                          <div className="city-list">
                            <span className="city-from">
                              {`${city}, ${state_code}`}
                            </span>
                            <img
                              className="arrow-image"
                              src="img/arrow.svg"
                              alt="arrow"
                            />
                            <span className="city-to">{us_port.name}</span>
                          </div>
                          <div className="order-delivery">
                            <span>{`$${ground.toLocaleString('en')}`}</span>
                          </div>
                        </div>
                        <div className="main-delivery-city_wrap">
                          <div className="city-list">
                            <span className="city-from">{us_port.name}</span>
                            <img
                              className="arrow-image"
                              src="img/arrow.svg"
                              alt="arrow"
                            />
                            <span className="city-to">{`${name}, ${country.iso_2}`}</span>
                          </div>
                          <div className="order-delivery">
                            <span>{`$${ocean.toLocaleString('en')}`}</span>
                          </div>
                        </div>
                      </Fragment>
                    );
                  } else {
                    return theme === 'banner250' ? (
                      <div className="main-delivery-city_wrap">
                        <ul className="main-delivery-list">
                          <li className="delivery-from">
                            <span>{`${city}, ${state_code}`}</span>
                          </li>
                          <li className="delivery-to">
                            <span>
                              {countryState.type === TypeCanadaMexico
                                ? `${destinationTypeB[1]}, ${
                                    destinationTypeB[0]
                                  }`
                                : `${zip.city}, ${zip.state_code}`}
                            </span>
                          </li>
                        </ul>
                        <p className="delivery-price">{`$${ground.toLocaleString(
                          'en'
                        )}`}</p>
                      </div>
                    ) : (
                      <div className="main-delivery-city_wrap">
                        <div className="city-list">
                          <span className="city-from">
                            {`${city}, ${state_code}`}
                          </span>
                          <img
                            className="arrow-image"
                            src="img/arrow.svg"
                            alt="arrow"
                          />
                          <span className="city-to">
                            {countryState.type === TypeCanadaMexico
                              ? `${destinationTypeB[1]}, ${destinationTypeB[0]}`
                              : `${zip.city}, ${zip.state_code}`}
                          </span>
                        </div>
                        {theme === 'banner144' && (
                          <p className="delivery-price">{`$${ground.toLocaleString(
                            'en'
                          )}`}</p>
                        )}
                      </div>
                    );
                  }
                }}
              </MainConsumer>
            </div>
            <div className="button-wrap">
              <button
                type="button"
                onClick={handleChangeDestination}
                className="change-destination"
              >
                {dict('Change Destination')}
              </button>
            </div>
          </div>
        </div>
      )}
    </MainConsumer>
  );
};

export default DeliveryPart;
