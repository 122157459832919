import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';

import { Context } from '../context';
import { deliveryTypes } from '../constants';
import isMobile from '../utils/is-mobile';

import messages from './messages';

import './styles.scss';

const Modal = () => {
  const [isOpen, handleOpen] = useState();
  const { type } = useContext(Context);

  let content = messages.ground;

  if (type === deliveryTypes.international) {
    content = messages.sea;
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      {isOpen ? (
        <div className="modal">
          <img
            className="modal__thumb"
            height={content.icon.height}
            src={`img/${content.icon.src}`}
            width={content.icon.width}
            alt=""
          />
          <div className="modal__content">
            <p>
              {isMobile && content.mobileLabel
                ? content.mobileLabel
                : content.label}
            </p>
            <p>
              We’re here to help! Call us at{' '}
              <a href="tel:+13607763596" className="phone">
                +1 (360) 776-3596
              </a>
              &nbsp;or email&nbsp;
              <a href="mailto:info@EasyHaul.com">info@EasyHaul.com</a>
            </p>
          </div>
          <button
            className="btn-close banner__hint-button"
            onClick={() => handleOpen(false)}
          >
            <svg
              width="10"
              height="10"
              fill="none"
              viewBox="0 0 6 6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.25 0L6 .75.75 6 0 5.25 5.25 0z" fill="#000" />
              <path
                d="M0 .75L.75 0 6 5.25 5.25 6 2.625 3.375 0 .75z"
                fill="#000"
              />
            </svg>
          </button>
        </div>
      ) : (
        <button
          className="btn btn-icon banner__hint-button"
          onClick={() => handleOpen(true)}
        >
          ?
        </button>
      )}
    </React.Fragment>,
    document.querySelector('.banner')
  );
};

export default Modal;
