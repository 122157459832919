export default function getReAxios(
  axios,
  { delayMultiplierMs = 1000, retries = 3, axiosConfig = {} } = {}
) {
  return {
    ...axios,
    get: async function(url, config = {}) {
      const axiosArgs = [url, { ...axiosConfig, ...config }];

      async function retryGet(count = 0, url, params) {
        const getWithDelay = new Promise(function(resolve, reject) {
          setTimeout(() => {
            resolve(axios.get(url, params));
          }, delayMultiplierMs * count);
        });

        if (count < retries) {
          try {
            return await getWithDelay;
          } catch (e) {
            return await retryGet(count + 1, ...axiosArgs);
          }
        }

        return await getWithDelay;
      }

      return await retryGet(0, ...axiosArgs);
    }
  };
}
