import React from 'react';

import dict from '../../services/i18n/dict';
import './ErrorDataLoading.css';

const ErrorDataLoading = () => {
  const handleReloadPage = () => window.location.reload();
  return (
    <div className="err-data-body">
      <div className="err-data-text">
        <span>{dict('Error')}</span>
        <span>
          {dict('Please')}{' '}
          <span className="blue-color-text" onClick={handleReloadPage}>
            {dict('try again')}
          </span>
        </span>
      </div>
    </div>
  );
};
export default ErrorDataLoading;
