import React, { Fragment } from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import SelectLocationPage from '../SelectLocationPage';

import './LayoutStock.css';

const LayoutStock = () => {
  return (
    <MainConsumer>
      {({
        handleGetLotData,
        handleSetLotNumber,
        lotNumber,
        handleDeleteInputStock,
        handleGetQuoteInter,
        isValid,
        validError,
        validErrorText,
        stockInputValue,
        toggleModalStockInfo,
        validButtonDisabled,
        isStockInputFocus,
        toggleStockInputFocus,
        isCanadaMexico,
        toggleStockIntInputFocus
      }) => {
        const paramLocalStorage = localStorage.getItem('isValidLocalStorage')
          ? JSON.parse(localStorage.getItem('isValidLocalStorage'))
          : '';
        const valid = paramLocalStorage.isValid || isValid;

        return (
          <div
            className={`${valid ? 'bannerstock-background' : 'layout-stock'}`}
          >
            {valid ? (
              <SelectLocationPage
                isCanadaMexico={isCanadaMexico}
                toggleStockIntInputFocus={toggleStockIntInputFocus}
                handleGetQuoteInter={handleGetQuoteInter}
              />
            ) : (
              <div className="form-stock-lot">
                <div className="form-stock-lot-info">
                  <img
                    className="banner-info-logo--img"
                    src="img/eh_logo.svg"
                    alt="logo-banner-info"
                  />
                  <span>{dict('Ship IAA vehicleS with us')}</span>
                  <span>{dict('We make it easy')}</span>
                </div>
                <div
                  className={
                    stockInputValue && validError
                      ? `form-stock-lot-input focus stock-error`
                      : (stockInputValue && !validError) || isStockInputFocus
                      ? `form-stock-lot-input focus `
                      : `form-stock-lot-input`
                  }
                >
                  <div className="form-stock-lot-input__border">
                    <form
                      className="form-input"
                      onSubmit={handleGetLotData}
                      action=""
                    >
                      {stockInputValue ? (
                        <Fragment>
                          <input
                            value={lotNumber}
                            type="text"
                            onChange={handleSetLotNumber}
                            onFocus={toggleStockInputFocus}
                            onBlur={toggleStockInputFocus}
                            name="fdfsfsdf"
                          />
                          {!validButtonDisabled && (
                            <img
                              onMouseDown={handleDeleteInputStock}
                              src="./img/cross.svg"
                              alt="cross"
                            />
                          )}
                          <button
                            onMouseDown={handleGetLotData}
                            disabled={validButtonDisabled}
                          >
                            Validate
                          </button>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <input
                            value={lotNumber}
                            type="text"
                            onChange={handleSetLotNumber}
                            placeholder={dict(
                              'Enter Stock # to get guaranteed quote'
                            )}
                            className="stock-blur-input"
                            onFocus={toggleStockInputFocus}
                            onBlur={toggleStockInputFocus}
                          />
                        </Fragment>
                      )}
                      <label className="stock-label">
                        {dict('Enter stock  #')}
                      </label>
                    </form>
                  </div>
                  {validError && (
                    <span className="stock-valid-error">{validErrorText}</span>
                  )}
                </div>

                <div
                  className="form-stock-lot-option header-option"
                  onClick={toggleModalStockInfo}
                >
                  <span className="mark-question">?</span>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </MainConsumer>
  );
};

export default LayoutStock;
