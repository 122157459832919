function get(obj, _path, fallback) {
  if (!obj || !_path) {
    return fallback;
  }
  const path = Array.isArray(_path) ? _path : _path.split('.');
  let result = obj;
  let currentKey = path.shift();

  while (typeof currentKey !== 'undefined' && typeof result === 'object') {
    result = result[currentKey];
    currentKey = path.shift();
  }

  if (path.length || typeof result === 'undefined') {
    return fallback;
  }

  return result;
}

export default get;
