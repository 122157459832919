import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';

import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <div className="header-title">
        <img src="img/logo.svg" alt="logo" className="header-title-logo" />
        <span className="header-title_guaranteed">
          {dict('Shipping quote for')}&nbsp;
        </span>
        <MainConsumer>
          {({ make, model, year }) => (
            <span className="header-title_name-car">
              {year}
              &nbsp;
              <span className="header-title_name-make">{make}</span>
              &nbsp;
              {model}
            </span>
          )}
        </MainConsumer>
      </div>
      <MainConsumer>
        {({ toggleModalAbout }) => (
          <div className="header-option" onClick={toggleModalAbout}>
            <span className="mark-question">?</span>
          </div>
        )}
      </MainConsumer>
    </div>
  );
};

export default Header;
