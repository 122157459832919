import axios from 'axios';
import UrlData from './url-data';
import Cookies from 'universal-cookie';
import { destinationTrackingList } from '../constant';
import { handleRequestError } from './errorHandler';

import splitTests from './split-tests';

const cookies = new Cookies();
const user_agent = window.navigator.userAgent;
const { theme = 'default_banner', token } = UrlData;

export const initClientId = () => {
  const date = new Date();
  const timestamp = date.getTime();
  return `${Math.random()
    .toString(36)
    .substring(2, 8)}${Math.random()
    .toString(36)
    .substring(2, 8)}-${timestamp}`;
};

export const sendEvent = ({
  event,
  payload,
  custom1,
  custom2,
  custom3,
  trackingURL = `${process.env.REACT_APP_API_ENDPOINT}/event`
}) => {
  const themeCustom = splitTests.check('withCap') ? `${theme}_withCap` : theme;

  const data = {
    client_id: cookies.get('client_id') || initClientId(),
    event,
    user_agent,
    payload: payload || document.referrer,
    custom1: custom1 || themeCustom,
    custom2: custom2 || token
  };

  if (custom3) {
    data.custom3 = custom3;
  }

  if (trackingURL === destinationTrackingList.SB) {
    data.visitId = 'easyHaulBanner';
    data.hourId = 'easyHaulBanner';
  }
  return axios.post(trackingURL, data).catch(error => {
    handleRequestError(error, data);
  });
};
