import React from 'react';

import dict from '../../services/i18n/dict';
import { MainConsumer } from '../../contexts/ContextMain';
import './CustomDeliveryPart.css';

const CustomDeliveryPart = () => {
  return (
    <MainConsumer>
      {({ handleChangeDestination, theme, toggleModalContacts }) => (
        <div className="main-delivery custom-main-delivery">
          <div className="logo-iaai logo-iaai__custom">
            <img src="img/logo-iaai.svg" alt="logo-iaai" />
          </div>
          <div className="custom-main-delivery__content">
            {theme !== 'bannerstock' ? (
              <React.Fragment>
                <div className="custom-main-delivery__title">
                  {dict('Custom shipping quote is required for this vehicle')}
                </div>
                <div className="custom-main-delivery__label">
                  {dict(
                    'We need a little more time to give you an exact quote to this destination'
                  )}
                  &nbsp;
                  <button
                    onClick={toggleModalContacts}
                    className="custom-main-delivery__hint"
                  >
                    ?
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <span>{dict('Instant quote is not available')}</span>
            )}

            <div className="button-wrap">
              <button
                type="button"
                onClick={handleChangeDestination}
                className="change-destination"
              >
                {dict('Change Destination')}
              </button>
            </div>
          </div>
        </div>
      )}
    </MainConsumer>
  );
};

export default CustomDeliveryPart;
