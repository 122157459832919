import React, { Fragment } from 'react';

import Header from '../Header';
import MainContent from '../MainContent';
import { MainConsumer } from '../../contexts/ContextMain';
import BannerAbout from '../BannerAbout';
import BannerStockInfo from '../BannerStockInfo';
import BannerStockTransitTime from '../BannerStockTransitTime';
import BannerTransitTime from '../BannerTransitTime';
import LayoutStock from '../LayoutStock';
import Loader250 from '../Loader250';
import Loader144 from '../Loader144';
import LoaderStock from '../LoaderStock';
import CustomQuoteStock from '../CustomQuoteStock';

import UrlData from '../../services/url-data';
import { bannerThemeList } from '../../constant';

import './Layout.css';

const { theme } = UrlData;

const loaders = {
  banner250: <Loader250 />,
  banner144: <Loader144 />,
  bannerstock: <LoaderStock />
};

const Layout = () => (
  <MainConsumer>
    {({
      showModalStockInfo,
      showModalStockTransit,
      showModalAbout,
      showModalInfo,
      showModalContacts,
      isLoading,
      isCustomQuote
    }) => {
      const isBannerStockTheme = theme === 'bannerstock';

      switch (true) {
        case isCustomQuote && isBannerStockTheme:
          return <CustomQuoteStock />;
        case showModalStockInfo:
          return <BannerStockInfo />;
        case showModalStockTransit:
          return <BannerStockTransitTime />;
        case showModalAbout:
          return <BannerAbout />;
        case showModalContacts:
          return <BannerAbout showContacts />;
        case showModalInfo:
          return <BannerTransitTime />;
        default:
          return (
            <Fragment>
              {!isBannerStockTheme && <Header />}
              {!isLoading ? (
                isBannerStockTheme ? (
                  <LayoutStock />
                ) : (
                  <MainContent />
                )
              ) : bannerThemeList.includes(theme) ? (
                loaders[theme]
              ) : null}
            </Fragment>
          );
      }
    }}
  </MainConsumer>
);
export default Layout;
