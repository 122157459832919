import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import { bannerThemeList } from '../../constant';

import { MainConsumer } from '../../contexts/ContextMain';
import { sendEvent, initClientId } from '../../services/statistics';

import Layout from '../Layout/Layout';
import Loading from '../Loading/Loading';
import ErrorBoundary from '../ErrorBoundary';
import SalvagebidBanner from '../SalvagebidBanner/SalvagebidBanner';

import 'normalize.css';
import '../../index.css';
import './App.css';
import './banner-default.scss';
import './banner250.css';

class App extends Component {
  static renderContent() {
    return (
      <MainConsumer>
        {({ hasError, isLoading, theme, toggleBoundaryError }) => {
          if (isLoading && !bannerThemeList.includes(theme)) {
            return <Loading />;
          }
          return (
            <ErrorBoundary
              toggleBoundaryError={toggleBoundaryError}
              hasError={hasError}
            >
              <Layout />
            </ErrorBoundary>
          );
        }}
      </MainConsumer>
    );
  }

  componentDidMount() {
    const { cookies } = this.props;
    const date = new Date();
    const expires = new Date(date.getTime() + 3600 * 24 * 1000 * 365);
    let client_id = cookies.get('client_id');

    if (!client_id) {
      client_id = initClientId();
    }

    cookies.set('client_id', client_id, { expires, domain: '.easyhaul.com' });

    sendEvent({ event: 'eh_widget_show' });
  }

  render() {
    return (
      <MainConsumer>
        {({
          errorLoadingData,
          hasError,
          isCustomQuote,
          isLoading,
          theme,
          showModalAbout,
          showModalContacts,
          showModalInfo,
          showModalStockInfo,
          showModalStockTransit,
          showSbBanner
        }) => {
          const showBannerStockWithCustomQuote =
            theme === 'bannerstock' && isCustomQuote;

          const allowPointerEvents =
            hasError ||
            errorLoadingData ||
            showModalAbout ||
            showModalContacts ||
            showModalInfo ||
            showModalStockInfo ||
            showModalStockTransit ||
            showBannerStockWithCustomQuote;

          return (
            <div
              className={`wrapp ${
                isLoading && !bannerThemeList.includes(theme)
                  ? 'wrapp-loading-color'
                  : ''
              } ${theme ? theme : 'banner-default'}`}
            >
              <div className="container">
                {showSbBanner ? (
                  <SalvagebidBanner accent />
                ) : (
                  App.renderContent()
                )}
                <div
                  className={allowPointerEvents ? undefined : 'pointer-none'}
                  id="modal"
                />
              </div>
            </div>
          );
        }}
      </MainConsumer>
    );
  }
}

export default withCookies(App);
