import axios from 'axios';

import UrlData from '../url-data';
import {
  TypeCanadaMexico,
  TypeDomestic,
  TypeInternational
} from '../../constant';

const { token } = UrlData;
const api = process.env.REACT_APP_API_ENDPOINT;

function handleQuoteApiError(error) {
  if (
    error.response.status === 404 &&
    error.response.data.message === 'unable to get quote'
  ) {
    // '404 unable to get quote' - is expected api answer if quote unavailable for country
    return;
  }

  throw error;
}

export const getQuote = (
  type = TypeDomestic,
  {
    origin_zip,
    destination_zip,
    destination,
    destination_country,
    vin,
    auction,
    lot_number,
    auction_location_zip
  }
) => {
  const zip = origin_zip ? origin_zip : auction_location_zip;

  const params = {
    auction,
    drivable: false,
    lot_number,
    origin_zip: zip,
    token,
    type,
    vin
  };

  if (type === TypeInternational) {
    params.destination_country = destination_country;
  }

  if (type === TypeDomestic) {
    params.destination_zip = destination_zip;
  }

  if (type === TypeCanadaMexico) {
    params.destination = destination;
  }

  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'undefined') {
      delete params[key];
    }
  });

  return axios
    .get(`${api}/quote`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params
    })
    .catch(error => handleQuoteApiError(error));
};
