const LocalStorage = {
  safeCall(method, ...args) {
    try {
      return localStorage[method](...args);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }

    return '';
  },

  set(name, value) {
    this.safeCall('setItem', name, value);
  },

  get(name) {
    return this.safeCall('getItem', name);
  },

  remove(name) {
    this.safeCall('removeItem', name);
  }
};

export default LocalStorage;
