import React, { useContext } from 'react';
import { Context } from './context';

import Content from './content';
import Header from './header';
import Loader from './loader';
import Modal from './modal';
import Thumb from './thumb';
import StockFindForm from './stock-finder-form';

import './styles.scss';

const BannerStock = () => {
  const { isLoading, lot } = useContext(Context);
  const isLotExist = Boolean(Object.keys(lot).length);

  return (
    <div className="banner">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Header />
          {isLotExist && <Thumb />}
          <div className="banner__content">
            {isLotExist ? (
              <Content />
            ) : (
              <Context.Consumer>
                {({ setLot }) => <StockFindForm setLot={setLot} />}
              </Context.Consumer>
            )}
          </div>
          <Modal />
        </React.Fragment>
      )}
    </div>
  );
};

export default BannerStock;
