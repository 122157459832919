import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: 'eh-widget@1.1',
  ignoreErrors: [
    /^No error$/,
    /__show__deepen/,
    /Access is denied/,
    /anonymous function: captureException/,
    /Blocked a frame with origin/,
    /console is not defined/,
    /DataCloneError/,
    /event is not defined/,
    /feedConf/,
    /MyIPhoneApp/,
    /snapchat.com/,
    /vid_mate_check is not defined/,
    /win\.document\.body/,
    /window\._sharedData\.entry_data/,
    /ztePageScrollModule/,
    'Unchecked runtime.lastError',
    "Error: You've been logged out by the server. Please log in again.",
    'AbortError: The operation was aborted.',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'frameElement',
    // google map issue
    'Could not load "util"',
    // axios error
    'Request aborted',
    // axios error
    'timeout of 0ms exceeded',
    // kaspersky error
    'GetCommandSrc'
  ],
  blacklistUrls: [
    /google-analytics\.com/,
    /translate\.googleusercontent\.com/,
    /googletagmanager\.com/,
    /amazonaws\.com/,
    /google\.com/,
    /hotjar\.com/,
    /googleapis\.com/,
    /cloudfront\.net/,
    /facebook\.(com|net)/,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /adsbygoogle/,
    /ampproject\/amphtml/,
    /localhost/,
    /position-observer/,
    /kaspersky-labs\.com/
  ]
});

export const handleRequestError = (error, extraData = {}) => {
  Sentry.setExtras(extraData);
  Sentry.captureException(error);
};
