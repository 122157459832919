import React from 'react';

import dict from '../../services/i18n/dict';
import ModalWindow from '../ModalWindow';

import { MainConsumer } from '../../contexts/ContextMain';
import UrlData from '../../services/url-data';
import { TypeInternational, TypeDomestic } from '../../constant';

import './BannerTransitTime.css';

const { theme } = UrlData;

const BannerTransitTime = () => {
  return (
    <ModalWindow domNode={document.querySelector('#modal')}>
      <MainConsumer>
        {({ toggleModalInfo, countryState }) => (
          <div className="banner-info-wrapp banner-transit">
            <div className="banner-info-container">
              <div className="banner-info-body">
                <div className="banner-info-logo">
                  {theme ? (
                    <img
                      className="banner-info-logo--img"
                      src="img/logo-info-banner.svg"
                      alt="logo-banner-info"
                    />
                  ) : !theme && window.innerWidth < 480 ? (
                    <img
                      className="banner-info-logo--img"
                      src={
                        countryState.type === TypeInternational
                          ? 'img/icon-ship.svg'
                          : 'img/icon-vehicle.svg'
                      }
                      alt="logo-banner-info"
                    />
                  ) : null}
                </div>
                <div className="banner-info-content">
                  <div className="banner-info-message">
                    <div className="banner-info-icon banner-info-icon-car">
                      <img
                        className={
                          countryState.type === TypeDomestic
                            ? 'banner-info-logo--icon banner-info-logo--icon-no-reverse'
                            : 'banner-info-logo--icon'
                        }
                        src={
                          countryState.type === TypeInternational
                            ? 'img/icon-ship.svg'
                            : 'img/icon-vehicle.svg'
                        }
                        alt="logo-banner-info"
                      />
                    </div>
                    {countryState.type === TypeInternational ? (
                      <span>{dict('Please note that sailing time...')}</span>
                    ) : (
                      <span>{dict('Please note that transit time...')}</span>
                    )}
                  </div>
                </div>
                <div onClick={toggleModalInfo} className="close-window-info">
                  <img src="./img/cross.svg" alt="cross" />
                </div>
              </div>
            </div>
          </div>
        )}
      </MainConsumer>
    </ModalWindow>
  );
};

export default BannerTransitTime;
