import React from 'react';

import ModalWindow from '../ModalWindow';
import { MainConsumer } from '../../contexts/ContextMain';
import dict from '../../services/i18n/dict';

import './BannerAbout.css';

const BannerAbout = ({ showContacts }) => {
  return (
    <ModalWindow domNode={document.querySelector('#modal')}>
      <MainConsumer>
        {({ toggleModalAbout, toggleModalContacts }) => (
          <div className="banner-info-wrapp">
            <div className="banner-info-container">
              <div className="banner-info-body">
                <div className="banner-info-logo">
                  <img
                    className="banner-info-logo--img"
                    src="img/logo-info-banner.svg"
                    alt="logo-banner-info"
                  />
                </div>
                <div className="banner-info-content">
                  <div className="banner-info-text">
                    {showContacts ? (
                      <>
                        <div>
                          <strong>Questions?</strong>
                        </div>
                        <strong>
                          Contact us at{' '}
                          <a href="tel:+13605979320">+1 (360) 597-9320</a>
                        </strong>
                        <div className="banner-info-text__desc">
                          between 6AM and 4PM PST and our friendly agents will
                          be happy to help you
                        </div>
                      </>
                    ) : (
                      <span>{dict('EasyHaul.com is an independent...')}</span>
                    )}
                  </div>
                </div>
                <div
                  onClick={
                    showContacts ? toggleModalContacts : toggleModalAbout
                  }
                  className="close-window-about"
                >
                  <img src="./img/cross.svg" alt="cross" />
                </div>
              </div>
            </div>
          </div>
        )}
      </MainConsumer>
    </ModalWindow>
  );
};
export default BannerAbout;
