import React, { Component } from 'react';
import Select from 'react-select';
import { MainConsumer } from '../../contexts/ContextMain';

class SelectComponent extends Component {
  render() {
    const {
      placeholder,
      options,
      onChange,
      value,
      canadaFirstRender,
      openMenuOnFocus,
      filterOption,
      selectToggleFocus
    } = this.props;
    return (
      <MainConsumer>
        {({ isError, toggleStockIntInputFocus }) => {
          return (
            <Select
              className={
                isError
                  ? 'select input-autocomplete-error'
                  : 'select select-port'
              }
              placeholder={placeholder}
              classNamePrefix="eh-select"
              options={options}
              onChange={onChange}
              value={value}
              onFocus={() => selectToggleFocus(toggleStockIntInputFocus)}
              onBlur={() => selectToggleFocus(toggleStockIntInputFocus)}
              menuIsOpen={canadaFirstRender ? true : undefined}
              openMenuOnFocus={openMenuOnFocus}
              filterOption={filterOption}
            />
          );
        }}
      </MainConsumer>
    );
  }
}

export default SelectComponent;
