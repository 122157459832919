import React from 'react';
import PropTypes from 'prop-types';
import parse from 'url-parse';
// ie 11 Promise.finally && .all polyfill
import 'core-js/features/promise';

import get from '../../utils/get';
import isMobile from '../utils/is-mobile';
import LocalStorage from '../utils/local-storage';
import pullGeoPosition from '../utils/pull-geo-position';
import removeStocknoFromUrl from '../utils/remove-stockno-from-url';
import { auctionTypes, deliveryTypes } from '../constants';
import { Location, Lot, Quote } from '../services/api/';

const Context = React.createContext();

class ContextProvider extends React.Component {
  constructor(props) {
    super(props);

    const url = parse(document.URL, true);
    const { stockno, token, auction } = get(url, 'query', {});
    const lotNumbers = stockno ? stockno.split(',') : [];

    this.queryParams = {
      auction,
      token,
      lotNumbers
    };
    const destination = JSON.parse(LocalStorage.get('destination2'));
    const lot = JSON.parse(LocalStorage.get('lot2')) || {};
    // TODO: check if there are any other params
    // || queryParams.zip && queryParams.country;
    const type = LocalStorage.get('type2');

    this.state = {
      countries: [],
      lot,
      lots: [],
      destination,
      isLoading: true,
      quote: null,
      type
    };
  }

  async componentDidMount() {
    let { destination, lot, type } = this.state;
    try {
      const [lots, countries] = await Promise.all([
        Lot.getAll(this.queryParams.lotNumbers),
        Location.getCountries()
      ]);
      const isPredefinedLot = lots.some(
        ({ lot_number }) => lot_number === get(lot, 'lot_number')
      );

      if (!destination && !type) {
        const geoPosition = await pullGeoPosition(countries);
        destination = geoPosition.destination;
        type = geoPosition.type;
      }

      if (isPredefinedLot) {
        this.pullAndSetQuote(type, destination, lot).then(() => {
          this.setState({
            lots,
            countries
          });
        });

        return;
      }

      this.pullQuoteRace({ destination, type, lots })
        .then(quote => {
          this.setState({
            lots,
            countries,
            ...quote
          });
        })
        .catch(e => {
          this.setState({
            lots,
            countries,
            lot: lots[0],
            destination,
            type
          });
        })
        .finally(() => {
          this.setLoading(false);
        });
    } catch (e) {
      if (!isMobile) {
        removeStocknoFromUrl();
      }
    }
  }

  pullQuote = (type, destination, lot) => {
    if (!type || !destination) {
      return;
    }

    const { auction_location_zip, lot_number, vin } = lot;

    let params = {
      auction: auctionTypes.iaa,
      vin,
      lot_number,
      token: this.queryParams.token,
      origin_zip: auction_location_zip,
      drivable: false,
      type
    };

    if (type === deliveryTypes.domestic) {
      const { zip: destination_zip } = destination;
      params.destination_zip = destination_zip;
    }

    if (type === deliveryTypes.international) {
      params.destination_country = destination.countryId;
    }

    if (type === deliveryTypes.canadaOrMexico) {
      params.destination = destination.id;
    }

    this.setLoading(true);

    return Quote.get(params)
      .then(quote => {
        let newDestination = destination;
        const quoteDestination = get(quote, 'destination.zip', {});
        const { state_code, city, state, zip } = quoteDestination;

        if (type === deliveryTypes.domestic) {
          newDestination = {
            city,
            state,
            state_code,
            zip
          };
        }

        if (type === deliveryTypes.international) {
          const { id, ...restDestination } = get(
            quote,
            'destination.country',
            destination
          );

          newDestination = {
            ...restDestination,
            countryId: id,
            name: get(quote, 'destination.name'),
            us_port: get(quote, 'us_port.name')
          };
        }

        if (type === deliveryTypes.canadaOrMexico) {
          newDestination = {
            city,
            countryId: destination.countryId,
            name: get(quote, 'destination.name'),
            id: get(quote, 'destination.id'),
            state,
            state_code,
            zip
          };
        }

        return {
          destination: newDestination,
          lot,
          quote,
          type
        };
      })
      .catch(() => {
        return null;
      })
      .finally(() => {
        LocalStorage.set('destination2', JSON.stringify(destination));
        LocalStorage.set('lot2', JSON.stringify(lot));
        LocalStorage.set('type2', type);
      });
  };

  pullAndSetQuote = (type, destination, lot) => {
    return this.pullQuote(type, destination, lot)
      .then(quote => {
        if (quote) {
          this.setState({
            ...quote
          });
        } else {
          this.setState({
            type,
            destination,
            lot,
            quote: null
          });
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  // This method is used on app init
  // If we know destination
  // We are looking for the first stockno
  // With non empty quote
  pullQuoteRace = async ({ destination, type, lots }) => {
    if (!destination && !type) {
      throw new Error();
    }

    const lastLotIdx = lots.length - 1;

    for (let i = 0; i < lots.length; i++) {
      const quote = await this.pullQuote(type, destination, lots[i]);
      if (quote) {
        return quote;
      }

      if (i === lastLotIdx) {
        throw new Error();
      }
    }
  };

  setLoading = isLoading => {
    this.setState({
      isLoading: Boolean(isLoading)
    });
  };

  setLot = lot => {
    this.setState({
      lot
    });
  };

  render() {
    const { children } = this.props;

    const value = {
      ...this.state,
      pullAndSetQuote: this.pullAndSetQuote,
      setLot: this.setLot
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
  }
}

ContextProvider.propTypes = { children: PropTypes.node };

export default ContextProvider;
export { Context };
