import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import dict from '../../services/i18n/dict';
import isMobile from '../utils/is-mobile';

import './styles.scss';

const Topic = ({ className, title, onChange, value }) => {
  const mobileButtonProps = isMobile
    ? {
        onClick: onChange,
        role: 'button'
      }
    : null;

  return (
    <div className={classNames('topic', className)}>
      <div className="topic__title">{title}</div>
      <div className="topic__value" {...mobileButtonProps}>
        {value}
      </div>
      {onChange && (
        <React.Fragment>
          &nbsp;
          <button
            className="btn btn--secondary btn--tiny topic__btn"
            type="button"
            onClick={onChange}
          >
            {dict('Change')}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

Topic.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
  onChange: PropTypes.func
};

export default Topic;
