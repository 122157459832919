import React, { useContext } from 'react';
import parse from 'url-parse';

import { Context } from '../context';
import { deliveryTypes } from '../constants';

const CustomQuoteLink = () => {
  const { destination, lot, type } = useContext(Context);
  const {
    auction_location_city,
    auction_location_state_code,
    auction_location_zip,
    make,
    model,
    year
  } = lot;
  const { id, city, state_code, zip, state, countryId } = destination || {};
  const from = JSON.stringify({
    zip: auction_location_zip,
    state_code: auction_location_state_code,
    city: auction_location_city
  });
  let to = JSON.stringify({
    id: countryId
  });

  if (type === deliveryTypes.domestic) {
    to = JSON.stringify({
      zip,
      city,
      state_code,
      state
    });
  }

  if (type === deliveryTypes.canadaOrMexico) {
    to = JSON.stringify({
      portId: id,
      id: countryId
    });
  }

  const params = {
    customQuote: true,
    from,
    make,
    model,
    type,
    year,
    to
  };

  const parsed = parse(
    process.env.REACT_APP_EH_SITE_URL || 'https://www.easyhaul.com/'
  );
  parsed.set('query', params);
  const link = parsed.toString();

  return (
    <a
      className="btn btn--primary btn--huge"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      Request <span className="hide-mobile">Custom</span> Quote
    </a>
  );
};

CustomQuoteLink.propTypes = {};

export default CustomQuoteLink;
