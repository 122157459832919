import React, { Component, Fragment } from 'react';
import { MainConsumer } from '../../contexts/ContextMain';
import { TypeInternational, TypeCanadaMexico } from '../../constant';

class LocationCustomerData extends Component {
  handleFormatNumber = num => num.toLocaleString('en-IN');

  render() {
    return (
      <MainConsumer>
        {({
          quoteParametersStock,
          handleChangeDestinationBannerStock,
          sendEventAndRedirectOnOrderSubmit,
          typeShipping
        }) => {
          let destinationTypeB, url;

          const quoteLocalStorage = localStorage.getItem('quoteParameters')
            ? JSON.parse(localStorage.getItem('quoteParameters'))
            : '';
          const quoteData = quoteLocalStorage || quoteParametersStock;

          const { destination, origin, us_port, quote = {} } = quoteData;
          const { total } = quote;

          if (
            typeShipping === TypeCanadaMexico &&
            quoteData &&
            quoteData.destination
          ) {
            destinationTypeB = quoteData.destination.name.split('-');
          }

          if (quoteData && quoteData.quote) {
            url = quoteData.quote.url;
          }

          return (
            <Fragment>
              {typeShipping === TypeInternational ? (
                <div className="data">
                  <p className="data-item ellipsis">
                    <span className="label">Destination: </span>
                    <strong>{`${destination.country.iso_2}, ${
                      destination.name
                    }${` `}`}</strong>

                    <button
                      type="button"
                      onClick={handleChangeDestinationBannerStock}
                    >
                      Change
                    </button>
                  </p>

                  <p className="data-item ellipsis">
                    <span className="label">Domestic: </span>
                    <Fragment>
                      <span>{`${origin.city}, ${origin.state_code}`}</span>
                      <img
                        className="arrow-image"
                        src="img/arrow.svg"
                        alt="arrow"
                      />
                      <span>{us_port.name}</span>
                    </Fragment>
                  </p>
                  <p className="data-item ellipsis">
                    <span className="label">International: </span>
                    <Fragment>
                      <span>{us_port.name}</span>
                      <img
                        className="arrow-image"
                        src="img/arrow.svg"
                        alt="arrow"
                      />
                      <span>
                        {`${destination.country.iso_2}, ${destination.name}`}
                      </span>
                    </Fragment>
                  </p>
                </div>
              ) : (
                <div className="data">
                  <p className="data-item ellipsis">
                    <span className="label">Destination: </span>
                    <strong>
                      {typeShipping === TypeCanadaMexico
                        ? `${destinationTypeB[1]}, ${destinationTypeB[0]}`
                        : `${destination.zip.city}, ${
                            destination.zip.state_code
                          }${` `}`}
                    </strong>

                    <button
                      type="button"
                      onClick={handleChangeDestinationBannerStock}
                    >
                      Change
                    </button>
                  </p>
                  <p className="data-item ellipsis">
                    <span className="label">Domestic: </span>
                    <Fragment>
                      <span>{`${origin.city}, ${origin.state_code}`}</span>
                      <img
                        className="arrow-image"
                        src="img/arrow.svg"
                        alt="arrow"
                      />
                      <span>
                        {typeShipping === TypeCanadaMexico
                          ? `${destinationTypeB[1]}, ${destinationTypeB[0]}`
                          : `${destination.zip.city}, ${destination.zip.state_code}`}
                      </span>
                    </Fragment>
                  </p>
                </div>
              )}
              <div className="shipping-quote">
                <span>
                  Guaranteed Quote:{' '}
                  <strong>{`$${this.handleFormatNumber(total)} USD`}</strong>
                </span>
                <div className="main-order-button-stock">
                  <a
                    target="_blank"
                    onClick={e => sendEventAndRedirectOnOrderSubmit(e, url)}
                    href={url}
                    rel="noopener noreferrer"
                  >
                    Order Shipping
                  </a>
                </div>
              </div>
            </Fragment>
          );
        }}
      </MainConsumer>
    );
  }
}

export default LocationCustomerData;
