import axios from 'axios';
import { CountryUS, TypeDomestic } from '../../constant';
import getReAxios from '../../utils/reAxios';

const api = process.env.REACT_APP_API_ENDPOINT;
const option = {
  headers: {
    'Content-Type': 'application/json'
  }
};

const reAxios = getReAxios(axios, { axiosConfig: option });

export const getGeoAutodetection = async () => {
  try {
    const destination = localStorage.getItem('destination');
    if (!destination) {
      return await reAxios.get(`${api}/geo/detect`);
    }
    const destinationLocalStorage = JSON.parse(destination);
    const result = {
      country:
        destinationLocalStorage.country &&
        destinationLocalStorage.country.country
    };
    if (destinationLocalStorage.type === TypeDomestic) {
      result.country = CountryUS;
      result.zip = destinationLocalStorage.zip.zip;
    }
    return { data: { ...result } };
  } catch (error) {
    console.error(error);
  }
};

export const getGeolocation = (lat, lon) => {
  return reAxios.get(`${api}/geolocation?latitude=${lat}&longitude=${lon}`);
};

export const getListOfCountry = () => {
  return reAxios.get(`${api}/destination-country`);
};

export const getCityByZip = zip => {
  return reAxios.get(`${api}/zip/${zip}`);
};

export const getListUSPorts = (id, latitude, longitude) => {
  return reAxios.get(
    `${api}/destination/${id}/port?lat=${latitude}&lon=${longitude}`
  );
};

export const getListDestinationsPort = id => {
  return reAxios.get(`${api}/destination?country=${id}`);
};
