import React from 'react';
import CustomQuoteLink from '../CustomQuoteLink';

import './CustomQuoteButton.css';

const CustomQuoteButton = () => {
  return (
    <div className="main-quote custom-quote">
      <div className="quote-button custom-quote-button">
        <CustomQuoteLink />
      </div>
    </div>
  );
};

export default CustomQuoteButton;
