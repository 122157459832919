import React from 'react';
import classNames from 'classnames';

import Lot from '../services/api/lot';

class StockFinderForm extends React.Component {
  state = {
    stockNumber: ''
  };

  handleChangeStockNumber = event => {
    const { value } = event.target;

    this.setState({
      stockNumber: value
    });
  };

  handleSubmit = () => {
    const { setLot } = this.props;
    const { stockNumber } = this.state;

    if (!stockNumber) {
      return;
    }

    Lot.get(stockNumber)
      .then(setLot)
      .catch(e => {
        this.setState({
          errorText: 'Stock# is not found'
        });
      });
  };

  render() {
    const { errorText, stockNumber } = this.state;

    return (
      <React.Fragment>
        <div className="banner__form">
          <input
            className="banner__input"
            onChange={this.handleChangeStockNumber}
            placeholder="Enter stock#"
            value={stockNumber}
          />
          <button
            className="btn btn--primary btn--huge"
            onClick={this.handleSubmit}
          >
            Validate
          </button>
        </div>
        <div className="action-bar">
          <div
            className={classNames({
              'danger-text': errorText
            })}
          >
            {errorText || 'To get instant quote enter stock#'}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StockFinderForm;
