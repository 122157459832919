// In some cases we get undefined converted to String from query
function filterQueryParams(queryObj) {
  for (let prop in queryObj) {
    if (queryObj[prop] === 'undefined') {
      delete queryObj[prop];
    }
  }

  return queryObj;
}

export default filterQueryParams;
