class LocalStorage {
  static safeCall(method, ...args) {
    try {
      return localStorage[method](...args);
    } catch (e) {}

    return null;
  }

  static set(name, value) {
    LocalStorage.safeCall('setItem', name, value);
  }

  static get(name) {
    return LocalStorage.safeCall('getItem', name);
  }

  static remove(name) {
    LocalStorage.safeCall('removeItem', name);
  }
}

export default LocalStorage;
