import isRtl from '../../utils/isRtl';
import getUserLang from '../../services/i18n/get-user-lang';
import React from 'react';
import Helmet from 'react-helmet';

const lang = getUserLang() || 'en';

const HtmlTagAttributes = () => {
  return (
    <Helmet>
      <html lang={lang} dir={isRtl(lang) ? 'rtl' : 'ltr'} />
    </Helmet>
  );
};

export default HtmlTagAttributes;
