import axios from 'axios';
import getReAxios from '../../utils/reAxios';

const api = process.env.REACT_APP_API_ENDPOINT;
const option = {
  headers: {
    'Content-Type': 'application/json'
  }
};

const reAxios = getReAxios(axios, { axiosConfig: option });

export const getLotData = (auction, lot_number) => {
  return reAxios.get(`${api}/lot?auction=${auction}&lot_number=${lot_number}`);
};
